import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForTSIV = () => {
    const imagePaths = {
        tsiv_2: require("../assets/img/TSIV-2.png"),
        tsiv_3: require("../assets/img/TSIV-3.png"),
        tsiv_4: require("../assets/img/TSIV-4.png"),
        tsiv_5: require("../assets/img/TSIV-5.png"),
        tsiv_6: require("../assets/img/TSIV-6.png"),
        tsiv_7: require("../assets/img/TSIV-7.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Why Pick Our Chauffeur-driven Taxis in Vijayawada?</h2>
            <div className="container">

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tsiv_2}
                            alt="taxi service in vijayawada: tsiv_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Professional and Courteous Drivers</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>In Vijayawada, our taxi services provide professional and experienced drivers who prioritize your safety and comfort throughout the journey.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tsiv_3}
                            alt="taxi service in vijayawada: tsiv_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Complete Sense of Convenience</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>With a professional driver at the wheel, you can unwind and fully enjoy the ride without any concerns about navigation, traffic, or parking. This allows you to focus on your activities or simply relax while we take care of driving.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />


                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tsiv_4}
                            alt="taxi service in vijayawada: tsiv_4"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Local Expertise & Enhanced Comfort</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Our chauffeurs in Vijayawada are highly knowledgeable about the roads, traffic patterns, and popular destinations, ensuring timely and efficient travel to your desired locations. Moreover, our chauffeur-driven taxis are equipped with well-maintained vehicles and amenities, guaranteeing a comfortable and pleasant travel experience for all passengers.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tsiv_5}
                            alt="taxi service in vijayawada: tsiv_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Flexibility at Your Convenience</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Chauffeur-driven taxi services in Vijayawada allow you to tailor your itinerary and make unplanned stops or detours as necessary during your journey, offering a customized travel experience. This service allows you to adjust your schedule based on your needs, ensuring convenience and comfort throughout the trip.</p>

                    </div>
                </div>

                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tsiv_6}
                            alt="taxi service in vijayawada: tsiv_6"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Stress-free Travel</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>By choosing chauffeur-driven taxis, you can alleviate the hassle of navigating unfamiliar areas, particularly for visitors or travelers who are new to the city. This professional service ensures a stress-free and convenient travel experience, allowing you to relish your time in the city without worrying about driving. </p>

                    </div>
                </div>

                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tsiv_6}
                            alt="taxi service in vijayawada: tsiv_7"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Pick up and Drop Service</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>SR Car Travels provides a pick-up and drop service already included in the rental fee, eliminating additional costs. Our drivers will conveniently pick you up from your chosen or preferred location, saving you the hassle of arranging transportation. Similarly, when returning the car, our driver will ensure a seamless drop-off experience by taking you directly to your preferred destination without requiring you to search for parking space.</p>

                    </div>
                </div>

                <hr style={thinLineStyle} />

            </div>

        </>
    );
};

export default SRTravelOptionsForTSIV;
