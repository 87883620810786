import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForTaxiFromVijToHyd = () => {
    const faqs = [
        {
            question: "Why should I book a cab from Vijayawada to Hyderabad?",
            answer: "The road trip from Vijayawada to Hyderabad can be conveniently covered by booking a cab with SR Car Travels. This ensures a safe and comfortable journey for you and your loved ones, allowing you to relax while our skilled driver takes care of the rest. With a taxi ride from us, you can enjoy the convenience of a clean and sanitized cab that picks you up at your doorstep."
        },
        {
            question: "Can I select the car model for my trip as per my preference?",
            answer: "You can select any car model from our diverse range of sedans, innovas, and SUVs for your upcoming trip from Vijayawada to Hyderabad. Enjoy the freedom to choose a vehicle that suits your preferences and travels with comfort and style."
        },
        {
            question: "How long does it take from Vijayawada to Hyderabad by road?",
            answer: "The travel time ranges from approximately 5.5 to 6.5 hours, with variability based on traffic conditions."
        },
        {
            question: "Do you provide continuous 24/7 assistance for all your customers?",
            answer: "We are dedicated to delivering efficient and reliable 24/7 assistance, ensuring our customers receive the best taxi services. Our robust support system is designed to serve to all your transportation needs, providing you with seamless access."
        },
        {
            question: "Does your taxi charges are all inclusive of driver charges?",
            answer: "Rest assured, the price includes driver charges and night drive charges. It's all taken care of to ensure a seamless travel experience."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForTaxiFromVijToHyd;
