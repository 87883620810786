import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const RidesDashboardComponent = ({ data }) => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    const navigate = useNavigate();
    const [rides, setRides] = useState(userData?.rides || []); // Initialize rides from userData or empty array
    const [message, setMessage] = useState({ type: '', content: '' });

    useEffect(() => {
        if (!userData) {
            navigate('/login');
        }

    }, [navigate, userData]);

    const markRideAsCompleted = async (rideId) => {
        try {
            // Assuming you have an endpoint to mark a ride as completed
            const response = await axios.post(`https://srcartravels.in/updateRideStatus/${rideId}`);
            if (response.status === 200) {
                // Success - Update UI or show success message
                setMessage({ type: 'success', content: 'Ride marked as completed successfully!' });
                // Optionally refresh the rides list or update the state to reflect the change
                const updatedRides = rides.map(ride =>
                    ride.id === rideId ? { ...ride, completed: true } : ride // Assuming `completed` field marks completion
                );
                setRides(updatedRides);
                setTimeout(() => {
                    navigate('/login'); // Adjust this path as needed
                }, 3000);
            }
        } catch (error) {
            console.error('Failed to mark ride as completed:', error);
            // Handle errors or show an error message
            setMessage({ type: 'danger', content: 'Failed to mark ride as completed.' });
        }
    };

    return (
        <>
            <Helmet>
                <title>Rides Dashboard - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container mt-4">
                <h1 className="text-center mb-4">Rides Dashboard</h1>
                {message.content && (
                    <div className={`alert alert-${message.type} text-center`} role="alert">
                        {message.content}
                    </div>
                )}
                <div className="d-flex justify-content-center mb-3">
                    <button className="btn btn-outline-primary me-2" onClick={() => navigate('/attendance')}>Attendance</button>
                </div>
                <div className="row">
                    <div className="col-12">
                        {rides.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Booking Name</th>
                                            <th>Pickup</th>
                                            <th>Dropoff</th>
                                            <th>Cost</th>
                                            <th>Date & Time</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rides.map((ride) => (
                                            <tr key={ride.id}>
                                                <td>{ride.bookingName}</td>
                                                <td>{ride.pickupLocation}</td>
                                                <td>{ride.dropoffLocation}</td>
                                                <td>₹{ride.totalCost}</td>
                                                <td>{new Date(ride.pickupDateTime).toLocaleString()}</td>
                                                <td>
                                                    <button className="btn btn-success btn-sm" onClick={() => markRideAsCompleted(ride.id)}>Mark as Completed</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="text-center">
                                <p>No rides available. Please check back later.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RidesDashboardComponent;
