import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForCarRentalsInVijayawada = () => {
    const faqs = [
        {
            question: "What are outstation cab services?",
            answer: "Outstation cab services are transportation options that enable travelers to book a cab for travel to destinations beyond the city or region where the service is based. These convenient services cater to travelers planning trips to nearby cities, tourist attractions, or other out-of-town destinations."
        },
        {
            question: "Do I need to pay any advance or booking fees when reserving a car with SR Car Travels?",
            answer: "You can easily book our cabs in Vijayawada by simply paying 500 rupees."
        },
        {
            question: "Can I modify or cancel my booking with SR Car Travels?",
            answer: "Yes, you can modify or cancel your booking with SR Car Travels, per the company's cancellation policy. Please note that depending on when you make your request and the specific terms of your booking, there may be applicable fees or charges."
        },
        {
            question: "What happens if my flight or train is delayed, and I miss my scheduled pick-up time with SR Car Travels?",
            answer: "If you expect a delay in your arrival time due to flight or train delays, please notify our team as soon as possible. While the company will endeavor to accommodate your updated schedule, it's important to note that additional charges may apply for extended waiting times or rescheduling."
        },
        {
            question: "Is there a limit to the duration of an outstation trip with SR Car Travels?",
            answer: "When booking an outstation trip with SR Car Travels, the duration is typically flexible. Rental rates may be calculated based on the distance traveled or the duration of your trip, depending on our company's pricing structure."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForCarRentalsInVijayawada;
