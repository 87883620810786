import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const DriverLeaveRecords = () => {
    const [leaveRecords, setLeaveRecords] = useState([]);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchDriverLeaves = async () => {
            try {
                const { data } = await axios.get('https://srcartravels.in/driver-all-leaves');
                setLeaveRecords(data);
            } catch (error) {
                console.error('Failed to fetch driver leave records:', error);
            }
        };

        fetchDriverLeaves();
    }, []);

    // Function to handle the Back button click
    const handleBackClick = () => {
        navigate('/role_owner'); // Navigate to the role_owner page
    };

    return (
        <>
            <Helmet>
                <title>Driver Leave Records - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container mt-5">
                <h2 className="mb-4 text-center">Driver Leave Records</h2>
                <div className="text-center mt-4">
                    <button className="btn btn-secondary" onClick={handleBackClick}>
                        <i className="bi bi-arrow-left"></i> Back
                    </button>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Driver Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaveRecords.map((record) => (
                                <tr key={record.id}>
                                    <td>{record.driver ? record.driver.name : 'N/A'}</td>
                                    <td>{record.startDate}</td>
                                    <td>{record.endDate}</td>
                                    <td>{record.reason}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
};

export default DriverLeaveRecords;
