import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const locations = [
  // Kanaka Durgamma Temple
  {
    id: 'kanakaDurgamma',
    title: 'Kanaka Durgamma Temple',
    description: 'The Kanaka Durgamma Temple is a Hindu temple located in Vijayawada, Andhra Pradesh, India. It is dedicated to Goddess Kanaka Durga, an incarnation of the Hindu deity Durga. The temple is considered to be one of the most powerful and popular temples in South India and attracts a large number of devotees from all over the country. The temple is located on the Indrakeeladri Hill, which is surrounded by the Krishna River on three sides. The temple is known for its architectural beauty and religious significance, and it is an important center of pilgrimage for Hindus.',
    images: ['/assets/img/durgamma/slide-1.png', '/assets/img/durgamma/slide-2.png', '/assets/img/durgamma/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
  // Kondapalli Fort
  {
    id: 'kondapalliFort',
    title: 'Kondapalli Fort',
    description: 'Kondapalli Fort is a historical fort located in Kondapalli village, near Vijayawada, in Andhra Pradesh, India. It was built by the Eastern Chalukya dynasty in the 14th century and was later occupied by the Vijayanagara Empire and the Qutub Shahi dynasty. The fort was used for military purposes and as a defense against invading forces.',
    images: ['/assets/img/kondapalli/slide-1.png', '/assets/img/kondapalli/slide-2.png', '/assets/img/kondapalli/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
  // Victoria Jubilee Museum
  {
    id: 'victoriaJubileeMuseum',
    title: 'Victoria Jubilee Museum',
    description: 'The Victoria Jubilee Museum, also known as the Andhra Pradesh State Museum, is a museum located in Vijayawada, Andhra Pradesh, India. It was established in 1887, during the reign of Queen Victoria, to commemorate her Jubilee year. The museum is one of the oldest in the state and houses a rich collection of artifacts and specimens related to the history, culture, and heritage of the region.',
    images: ['/assets/img/victoria/slide-1.png', '/assets/img/victoria/slide-2.png', '/assets/img/victoria/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
  // Undavalli Caves
  {
    id: 'undavalliCaves',
    title: 'Undavalli Caves',
    description: 'The Undavalli Caves are ancient rock-cut Hindu temples located in Undavalli village, near Vijayawada, Andhra Pradesh, India. These caves are considered to be one of the finest examples of Indian rock-cut architecture and are believed to have been built between the 4th and 6th centuries AD.',
    images: ['/assets/img/undavalli/slide-1.png', '/assets/img/undavalli/slide-2.png', '/assets/img/undavalli/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
  // Amaravathi Temple
  {
    id: 'amaravathiTemple',
    title: 'Amaravathi Temple',
    description: 'The Amaravathi Temple is an ancient Hindu temple located in the village of Amaravathi, near Vijayawada, Andhra Pradesh, India. It is dedicated to Lord Shiva and is one of the oldest and most revered shrines in the region.',
    images: ['/assets/img/amaravathi/slide-1.png', '/assets/img/amaravathi/slide-2.png', '/assets/img/amaravathi/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
  // Panakala Lakshmi Narasimha Temple
  {
    id: 'panakalaLakshmiNarasimhaTemple',
    title: 'Panakala Lakshmi Narasimha Temple',
    description: 'The Panakala Lakshmi Narasimha Temple is a Hindu temple located in Mangalagiri, Andhra Pradesh, India. It is dedicated to Lord Narasimha, an incarnation of Lord Vishnu, and is known for its unique form of worship involving offering a sweet pudding made from jaggery and flour, known as Panakam.',
    images: ['/assets/img/panakala/slide-1.png', '/assets/img/panakala/slide-2.png', '/assets/img/panakala/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
  // Bhavani Islands
  {
    id: 'bhavaniIslands',
    title: 'Bhavani Islands',
    description: 'Bhavani Islands are a group of small islands located on the river Krishna in Andhra Pradesh, India. These islands are known for their scenic beauty, rich cultural heritage, and abundant wildlife.',
    images: ['/assets/img/bhavani/slide-1.png', '/assets/img/bhavani/slide-2.png', '/assets/img/bhavani/slide-3.png'],
    bookNowLink: 'tel:8886556632',
  },
];


const LocationCard = ({ location }) => {
  return (

    <div className="card">
      <div id={`${location.id}Carousel`} className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {location.images.map((_, index) => (
            <button key={index} type="button" data-bs-target={`#${location.id}Carousel`} data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-label={`Slide ${index + 1}`}></button>
          ))}
        </div>
        <div className="carousel-inner">
          {location.images.map((img, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <img src={img} className="d-block w-100" alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target={`#${location.id}Carousel`} data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target={`#${location.id}Carousel`} data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="card-body">
        <h4 className="card-title text-center">{location.title}</h4>
        <p className="card-text" style={{ textAlign: 'left' }}>{location.description}</p>
        <a href={location.bookNowLink} className="btn btn-primary">Book Now</a>
      </div>
    </div>
  );
};

const LocationsCarousel = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://stackpath.bootstrapcdn.com/bootstrap/5.3.2/js/bootstrap.bundle.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Vijayawada City Tour| Vijayawada to Hyderabad Cabs | SR Car Travels</title>
        <meta name="description" content="Vijayawada is a city located in the state of Andhra Pradesh in India. SR Car Travels always try to provide good facility so that travelers can enjoy a stress-free." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://srcartravels.in/vijayawada-city-tour" />

        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta property="og:title" content="Vijayawada City Tour| Vijayawada to Hyderabad Cabs | SR Car Travels" />
        <meta property="og:description" content="Vijayawada is a city located in the state of Andhra Pradesh in India. SR Car Travels always try to provide good facility so that travelers can enjoy a stress-free." />
      </Helmet>

      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1>Vijayawada City Tour Must Visit Places</h1>
            <p><i><strong>Advisable to book your travel arrangements in advance to ensure that you have a smooth and comfortable journey. <a href="https://srcartravels.in/" style={{ color: 'inherit', textDecoration: 'underline' }}>Sr Car Travels</a> always try to provide good facility so that travelers can enjoy a stress-free and memorable trip without worrying anything.</strong></i></p>
            <p><i><strong>Vijayawada is a city located in the state of Andhra Pradesh in India and is known for its rich history and cultural heritage.</strong></i></p>
          </div>
          <hr className="rounded" />
          {locations.map((location) => (
            <div key={location.id} className="col-12 col-md-6 mb-4">
              <LocationCard location={location} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LocationsCarousel;
