import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const DriverLeaveForm = () => {
    const navigate = useNavigate();
    const [drivers, setDrivers] = useState([]);
    const [formData, setFormData] = useState({
        driverId: '',
        startDate: '',
        endDate: '',
        reason: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                const response = await axios.get('https://srcartravels.in/driver-details');
                setDrivers(response.data);
            } catch (error) {
                console.error('Error fetching drivers:', error);
            }
        };
        fetchDrivers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            console.log(formData);
            await axios.post('https://srcartravels.in/driver-leaves/submit', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setSuccessMessage('Leave request submitted successfully.');
            setTimeout(() => {
                navigate('/role_owner');
            }, 3000);
        } catch (error) {
            console.error('Error submitting leave request:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Driver Leave Form - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container mt-5">
                <h2 className="text-center mb-4"><i className="bi bi-calendar-x-fill"></i> Driver Leave Form</h2>
                {successMessage && (
                    <div className="alert alert-success text-center">{successMessage}</div>
                )}
                <form onSubmit={handleSubmit} className="row g-3 justify-content-center">
                    <div className="col-md-6">
                        <label htmlFor="driverId" className="form-label"><i className="bi bi-person-badge-fill"></i> Driver Name:</label>
                        <select className="form-select" id="driverId" name="driverId" value={formData.assignDriver} onChange={handleChange} required>
                            <option value="">Select a driver</option>
                            {drivers.map((driver) => (
                                <option key={driver.id} value={driver.id}>{driver.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="startDate" className="form-label"><i className="bi bi-calendar-plus-fill"></i> Start Date:</label>
                        <input type="date" className="form-control" id="startDate" name="startDate" value={formData.startDate} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="endDate" className="form-label"><i className="bi bi-calendar-minus-fill"></i> End Date:</label>
                        <input type="date" className="form-control" id="endDate" name="endDate" value={formData.endDate} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="reason" className="form-label"><i className="bi bi-pencil-square"></i> Reason:</label>
                        <input type="text" className="form-control" id="reason" name="reason" value={formData.reason} onChange={handleChange} required />
                    </div>
                    <div className="d-grid gap-2 col-md-6 mx-auto">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="bi bi-check-circle"></i>} Submit
                        </button>
                    </div>
                </form>
                <div className="text-center mt-3">
                    <a href="/role_owner" className="btn btn-link"><i className="bi bi-arrow-left-circle-fill"></i> Back to list</a>
                </div>
            </div>
        </>
    );
};

export default DriverLeaveForm;
