import React from 'react';
import SRHeader from '../components/common/SRHeader';
import SRCallNowButton from '../components/common/SRCallNowButton';
import SrCarTravelsMap from '../components/common/SrCarTravelsMap';
import SRFooter from '../components/common/SRFooter';
import SRCustomForm from '../components/common/SRCustomForm';

const ThankYouLayOut = ({ children }) => {
    return (
        <>
            <SRHeader />
            <hr className="rounded" />
            <SRCallNowButton />
            <hr className="rounded" />
            {children}
            <hr className="rounded" />
            <SrCarTravelsMap />
            <hr className="rounded" />
            <SRFooter />
        </>
    );
};

export default ThankYouLayOut;
