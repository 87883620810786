import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BookNowHydToVij = () => {
    // Correct the image path according to your project structure
    const imagePath = require('../assets/img/hv_img_5.png');

    return (
        <div className="container my-3" style={{ backgroundColor: '#113042', paddingTop: '60px', paddingBottom: '60px' }}> {/* Increased padding for more height */}
            <div className="row align-items-center">
                {/* Text content on the left for larger screens, above image on smaller screens */}
                <div className="col-md-8 text-center text-md-start order-2 order-md-1">
                    <h3 className="text-white mb-3 fs-5">Book Now, Ride Easy: 24/7 Support Makes SR Car Travels Your Trusted Choice</h3>
                    <div className='fs-6' style={{ color: '#E1E1E1' }}>
                        <p>The 24/7 support provided by SR Car Travels sets it apart as the premier choice for all your travel requirements. Whether you're scheduling a ride in the early morning hours or encountering unforeseen obstacles during your journey, our round-the-clock assistance guarantees that you can travel confidently, assured that help is readily accessible. This dedicated support elevates SR Car Travels into a dependable partner, delivering assurance and reliability throughout travel journeys.</p>
                        <p>Our professional team is accessible 24/7 to help you with any inquiries, bookings, or concerns, offering prompt and reliable assistance whenever you need it. Trust SR Car Travels for hassle-free travel and experience peace of mind and convenience.</p>
                        <p> Book Hyd to <a href='https://srcartravels.in/outstation'>Vijayawada cabs</a> now with us and enjoy continuous support at every step of your journey.</p>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                        <a type='button' href="https://srcartravels.in/" className="btn btn-lg fs-6" style={{ backgroundColor: "green", color: "#ffffff" }}>Know More</a>
                    </div>
                </div>
                {/* Image on the right for larger screens, below text on smaller screens */}
                <div className="col-md-4 mb-4 mb-md-0 order-1 order-md-2">
                    <LazyLoadImage
                        alt="Best Time For Renting a Car in Guntur"
                        src={imagePath}
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ width: '330px', height: '330px' }} // Ensure image is responsive
                    />
                </div>
            </div>
        </div>
    );
};

export default BookNowHydToVij;
