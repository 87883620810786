import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BookingOptions = () => {

    const imagePaths = {
        bookingImg: require("../assets/img/g_frame_2.png"),
        phoneImg: require("../assets/img/g_frame_3.png"),
        emailImg: require("../assets/img/g_frame_4.png")
    };

    const bookingOptions = [
        {
            imgSrc: imagePaths.bookingImg,
            title: "Booking Through Website",
            text: (<>
                If you are looking to <a href="https://srcartravels.in/car-rentals-vijayawada">rent a car in Guntur</a> for an outstation or local commute, you can conveniently visit the website to provide your pickup location, destination, and travel date. Following this, you can select the ideal car from our rental inventory based on your preferences and requirements. Our website provides transparent pricing information alongside the car models, allowing you to secure the best deal for your upcoming journey.
            </>),
        },
        {
            imgSrc: imagePaths.phoneImg,
            title: "Through Phone",
            text: (
                <span>
                    In order to book directly from our executives, you can ring us at <a href="tel:+918886556632">+91-8886556632</a> to book your upcoming journey. Kindly provide your details to our executive team; they will assist you with booking.
                </span>
            ),
        },
        {
            imgSrc: imagePaths.emailImg,
            title: "Via Email Inquiries",
            text: (
                <span>
                    Please feel free to contact us at <a href="mailto:contact@srcartravels.in">contact@srcartravels.in</a> for any inquiries or assistance with planning your itinerary. Our dedicated executives will respond promptly to address your needs. We look forward to being of service to you.
                </span>
            )
        }
    ];

    return (
        <div className="container py-4">
            <h2 className="mb-4 text-center fs-4">Book A Car Rental With SR Travels Anywhere and Anytime in Guntur</h2>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {bookingOptions.map((option, index) => (
                    <div key={index} className="col">
                        <div className="card h-100">
                            <LazyLoadImage
                                src={option.imgSrc} // Now using the correct image for each option
                                alt={option.title}
                                effect="blur"
                                className="card-img-top"
                            />
                            <div className="card-body text-center">
                                <h3 className="card-title fs-5">{option.title}</h3>
                                <p className="card-text fs-6">{option.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BookingOptions;
