import React, { useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import for blur effect

const PackageCard = ({ title, price, totalKm, extraKmPrice, imgSrc, altText, className, additionalInfo }) => {
  const razorpayFormRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.async = true;
    script.setAttribute('data-payment_button_id', 'pl_NEsP2iXg3H1RBj');
    const form = razorpayFormRef.current;
    if (form) {
      form.appendChild(script);
    }

    return () => {
      if (form && script.parentNode === form) {
        form.removeChild(script);
      }
    };
  }, []);

  return (
    <div className={`card mb-3 ${className}`} style={{ backgroundColor: '#173141', color: '#ffffff' }}>
      <LazyLoadImage
        src={imgSrc}
        alt={altText}
        effect="blur"
        wrapperClassName="card-img-top"
        loading="lazy"
        style={{ width: '100%', height: 'auto' }} // Adjust as needed
      />
      <div className="card-body">
        <h5 className="card-title text-center">{title}</h5>
        <div className="card-text text-center">
          <span className="text-red fw-bold fs-4">{price}</span> for up to {totalKm}Km + Rs.{extraKmPrice}/KM extra
          {additionalInfo && additionalInfo.map((info, index) => (
            <span key={index} className="d-block mt-2">
              {info.subtitle}: {info.price}, Max {info.totalKm} Km, Extra Rs.{info.extraKmPrice}/KM
            </span>
          ))}
        </div>
        <p className="text-center mt-3">
          🎉 Exclusive Offer: <strong>10% cash back</strong> on advance payment of 500! 🎁
        </p>
        <div className="d-flex justify-content-around align-items-center mt-4">
          <a href="tel:8886556632" className="btn" style={{ lineHeight: '2', backgroundColor: '#ffffff', color: '#173141' }}>Call Now</a>
          <form ref={razorpayFormRef} className="d-inline-block"></form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PackageCard);
