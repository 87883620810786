import React from 'react';
import { Link } from 'react-router-dom';

const SRCarTravelsInfo = () => {
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h3>Welcome to SR Car Travels: Your Gateway to Exploring Vijayawada and Beyond</h3>
                    <p className="mt-3">
                        Embark on an adventure with <strong>SR Car Travels</strong>, the leading name in <strong>car travels</strong> and <a href="https://srcartravels.in/cars-for-rent-vijayawada" style={{ color: 'inherit', textDecoration: 'underline' }}><strong>rental services</strong></a> in Vijayawada. Discover the convenience of personalized travel with our extensive fleet, ranging from <strong>luxury cars</strong> to <strong>budget-friendly rentals</strong>, ensuring your journey is nothing short of perfect.
                    </p>
                    {/* <div className="my-3">
                        <Link to="/best-car-travels-in-vijayawada" className="btn btn-info m-2">Discover Vijayawada</Link>
                        <Link to="/car-rentals-vijayawada" className="btn btn-info m-2">Flexible Car Rentals</Link>
                        <Link to="/car-travels-vijayawada" className="btn btn-info m-2">Explore with Us</Link>
                        <Link to="/car-travels-vijayawada-ap" className="btn btn-info m-2">Travel in Comfort</Link>
                        <Link to="/car-rentals-vijayawada-ap" className="btn btn-info m-2">Hassle-Free Rentals</Link>
                        <Link to="/car-travelers-near-me" className="btn btn-info m-2">Nearby Adventures</Link>
                        <Link to="/vijayawada-to-hyderabad-cabs" className="btn btn-info m-2">Vijayawada to Hyderabad</Link>
                        <Link to="/cabs-from-vijayawada-to-hyderabad" className="btn btn-info m-2">Cabs at Your Service</Link>
                        <Link to="/hyderabad-to-vijayawada-cab-booking" className="btn btn-info m-2">Book Your Ride</Link>
                        <Link to="/taxi-from-hyderabad-to-vijayawada" className="btn btn-info m-2">Reliable Taxis</Link>
                        <Link to="/cars-for-rent-vijayawada" className="btn btn-info m-2">Rentals Made Easy</Link>
                    </div> */}
                    <p>
                        Whether you're planning a <strong>trip from Vijayawada to Hyderabad</strong>, searching for <a href="https://srcartravels.in/car-travelers-near-me" style={{ color: 'inherit', textDecoration: 'underline' }}><strong>car travelers near you</strong></a>, or need a trustworthy <strong>taxi service</strong>, SR Car Travels stands ready to serve. Our commitment to excellence ensures your travel plans are executed flawlessly, with the highest standards of safety, comfort, and reliability.
                    </p>
                    <p className="fst-italic fw-bold">
                        Choose SR Car Travels for an unparalleled travel experience across Vijayawada and beyond. Let us be part of your next journey, where every mile is a story waiting to unfold.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SRCarTravelsInfo;
