import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RentACarHydToVij = () => {
    const imagePath = require("../assets/img/hv_img_6.png"); // Update the path to your actual image location

    return (
        <div className="container py-4">
            <div className="row align-items-center">
                {/* Image column */}
                <div className="col-md-4">
                    <LazyLoadImage
                        alt="Rent a Car in Guntur"
                        src={imagePath}
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ width: '350px', height: '320px' }}
                    />
                </div>
                {/* Content column */}
                <div className="col-md-8">
                    <h4 className="mb-2 fs-5">Summing It Up</h4>
                    <p className='fs-6'>SR Car Travels offers a seamless and convenient outstation travel experience from Hyderabad to Vijayawada. With a wide range of comfortable and reliable car rental options, including one-way drop services, customers can enjoy the flexibility, affordability, and punctuality they deserve. </p>
                    <p className='fs-6'>Choose from various car packages for your Hyderabad to Vijayawada trip, tailored to your itinerary. Rest assured, there are no extra charges with our transparent pricing for shared cab packages. SR Car Travels' Hyderabad to Vijayawada cabs service extends to both local and outstation travel, allowing you to book cabs from Hyderabad to explore your preferred destinations easily.   </p>
                    <p className='fs-6'>Want to know more about our cab services?</p>
                    <div className="mb-2 fs-6">
                        {/* Using Bootstrap Icons with clickable actions */}
                        <a href="tel:+918886556632" className="me-3" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <i className="bi bi-telephone-fill"></i><strong>+91-8886556632</strong>
                        </a>
                        <a href="mailto:contact@srcartravels.in" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <i className="bi bi-envelope-fill"></i> <strong>contact@srcartravels.in</strong>
                        </a>
                    </div>
                    {/* Custom styled button */}
                    <div className="mt-3">
                        <Button
                            variant="primary"
                            href="https://srcartravels.in/"
                            style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                        >
                            Know More
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RentACarHydToVij;
