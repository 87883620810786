import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BookingPage = () => {
    const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    const [booking, setBooking] = useState({
        assignDriver: '',
        bookingName: '',
        mobileNumber: '',
        vehicleName: '',
        pickupDateTime: '',
        pickupLocation: '',
        totalCost: '',
        dropoffDateTime: '',
        dropoffLocation: '',
        advance: '',
        isPaidFully: false,
        balance: '',
        totalKm: '',
        bookingType: '',
    });

    const [vehicles, setVehicles] = useState([]);
    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [dropoffSuggestions, setDropoffSuggestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const [drivers, setDrivers] = useState([]);
    useEffect(() => {
        if (!userData) {
            navigate('/login');
            return; // Exit early if no user data
        }

        // Function to fetch drivers
        const fetchDrivers = async () => {
            try {
                const response = await axios.get('https://srcartravels.in/driver-details'); // Adjust URL as needed
                setDrivers(response.data); // Assuming the API returns an array directly
            } catch (error) {
                console.error('Failed to fetch drivers', error);
            }
        };

        // Function to fetch vehicle names
        const fetchVehicles = async () => {
            try {
                const response = await axios.get('https://srcartravels.in/vehicles'); // Adjust URL as needed
                setVehicles(response.data); // Assuming the API returns an array directly
            } catch (error) {
                console.error('Failed to fetch vehicles', error);
            }
        };

        fetchDrivers();
        fetchVehicles();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setBooking({
            ...booking,
            [name]: type === 'checkbox' ? checked : value,
        });

        // Dynamically fetch city suggestions for pickup and dropoff locations
        if (name === 'pickupLocation' || name === 'dropoffLocation') {
            fetchCities(value, name === 'pickupLocation' ? setPickupSuggestions : setDropoffSuggestions);
        }
    };

    const fetchCities = async (query, setter) => {
        try {
            const response = await axios.get(`https://srcartravels.in/cities?search=${query}`);
            setter(response.data.cities);
        } catch (error) {
            console.error('Failed to fetch cities', error);
        }
    };

    const renderCitySuggestions = (suggestions, fieldName) => {
        return (
            <ul className="list-group position-absolute w-100">
                {suggestions.map((city, index) => (
                    <li key={index} className="list-group-item list-group-item-action" onClick={() => handleCitySelect(city, fieldName)}>
                        {city.cityName}
                    </li>
                ))}
            </ul>
        );
    };

    const handleCitySelect = (city, fieldName) => {
        setBooking({
            ...booking,
            [fieldName]: city.cityName,
        });
        // Clear suggestions after selection
        fieldName === 'pickupLocation' ? setPickupSuggestions([]) : setDropoffSuggestions([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear previous error messages
        setSuccessMessage(''); // Clear previous success messages

        try {
            const response = await axios.post('https://srcartravels.in/booking-details', booking, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data);
            // Handle success (e.g., show confirmation message)
            setSuccessMessage("Booking submitted successfully!");
            // Wait for 3 seconds before navigating
            setTimeout(() => {
                navigate('/role_owner'); // Adjust this path as needed
            }, 5000);

        } catch (error) {
            console.error('Failed to submit booking', error);
            // Handle different types of errors
            if (error.response) {
                // Server responded with a status code out of the range of 2xx
                const errorMessage = error.response.data.message || "Booking details already existed.";
                setErrorMessage(errorMessage);
            } else if (error.request) {
                // The request was made but no response was received
                setErrorMessage("No response from server. Please try again later.");
            } else {
                // An error occurred in setting up the request
                setErrorMessage(error.response.data.message);
            }
        }
    };



    return (
        <>
            <Helmet>
                <title>Booking - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="text-center mb-4">
                <button onClick={() => navigate('/role_owner')} className="btn btn-secondary">
                    <i className="bi bi-arrow-left-circle"></i> Back
                </button>
            </div>
            <div className="container mt-5">
                <h2 className="text-center mb-4">
                    <i className="bi bi-calendar-plus-fill"></i> Create Booking
                </h2>
                <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-md-6">
                        <label htmlFor="bookingName" className="form-label"><i className="bi bi-card-text me-2"></i>Booking Name</label>
                        <input type="text" maxlength="6" className="form-control" id="bookingName" name="bookingName" value={booking.bookingName} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="assignDriver" className="form-label"><i className="bi bi-person-badge me-2"></i>Assign Driver</label>
                        <select className="form-select" id="assignDriver" name="assignDriver" value={booking.assignDriver} onChange={handleChange} required>
                            <option value="">Select a Driver</option>
                            {Array.isArray(drivers) && drivers.map((driver) => (
                                <option key={driver.id} value={driver.name}>{driver.name}</option>
                            ))}
                        </select>

                    </div>
                    {/* Mobile Number */}
                    <div className="col-md-6">
                        <label htmlFor="mobileNumber" className="form-label"><i className="bi bi-telephone-fill me-2"></i>Mobile Number</label>
                        <input type="tel" className="form-control" id="mobileNumber" name="mobileNumber" pattern="[0-9]{10}" value={booking.mobileNumber} onChange={handleChange} required />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="vehicleName" className="form-label"><i className="bi bi-truck me-2"></i>Vehicle Name</label>
                        <select className="form-select" id="vehicleName" name="vehicleName" value={booking.vehicleName} onChange={handleChange} required>
                            <option value="">Select a Vehicle</option>
                            {Array.isArray(vehicles) && vehicles.map((vehicle) => (
                                <option key={vehicle.id} value={vehicle.id}>{vehicle.vehicleName}</option>
                            ))}

                        </select>
                    </div>

                    {/* Pickup DateTime */}
                    <div className="col-md-6">
                        <label htmlFor="pickupDateTime" className="form-label"><i className="bi bi-calendar2-plus-fill me-2"></i>Pickup DateTime</label>
                        <input type="datetime-local" className="form-control" id="pickupDateTime" name="pickupDateTime" value={booking.pickupDateTime} onChange={handleChange} required />
                    </div>

                    <div className="col">
                        <label htmlFor="pickupLocation" className="form-label"><i className="bi bi-geo-alt-fill me-2"></i>Pickup Location</label>
                        <input type="text" className="form-control" id="pickupLocation" name="pickupLocation" value={booking.pickupLocation} onChange={handleChange} required autoComplete="off" />
                        {renderCitySuggestions(pickupSuggestions, 'pickupLocation')}
                    </div>

                    {/* Total Cost */}
                    <div className="col-md-6">
                        <label htmlFor="totalCost" className="form-label"><i className="bi bi-cash-stack me-2"></i>Total Cost</label>
                        <input type="number" className="form-control" id="totalCost" name="totalCost" value={booking.totalCost} onChange={handleChange} required />
                    </div>

                    {/* Dropoff DateTime */}
                    <div className="col-md-6">
                        <label htmlFor="dropoffDateTime" className="form-label"><i className="bi bi-calendar2-x-fill me-2"></i>Dropoff DateTime</label>
                        <input type="datetime-local" className="form-control" id="dropoffDateTime" name="dropoffDateTime" value={booking.dropoffDateTime} onChange={handleChange} />
                    </div>

                    <div className="col">
                        <label htmlFor="dropoffLocation" className="form-label"><i className="bi bi-geo-fill me-2"></i>Dropoff Location</label>
                        <input type="text" className="form-control" id="dropoffLocation" name="dropoffLocation" value={booking.dropoffLocation} onChange={handleChange} autoComplete="off" />
                        {renderCitySuggestions(dropoffSuggestions, 'dropoffLocation')}
                    </div>

                    {/* Advance */}
                    <div className="col-md-6">
                        <label htmlFor="advance" className="form-label"><i className="bi bi-wallet2 me-2"></i>Advance</label>
                        <input type="number" className="form-control" id="advance" name="advance" value={booking.advance} onChange={handleChange} required />
                    </div>


                    {/* Balance */}
                    {/* <div className="col-md-6">
                    <label htmlFor="balance" className="form-label"><i className="bi bi-cash me-2"></i>Balance</label>
                    <input type="number" className="form-control" id="balance" name="balance" value={booking.balance} onChange={handleChange} required />
                </div> */}

                    {/* Total Km */}
                    <div className="col-md-6">
                        <label htmlFor="totalKm" className="form-label"><i className="bi bi-speedometer2 me-2"></i>Total Km</label>
                        <input type="number" className="form-control" id="totalKm" name="totalKm" value={booking.totalKm} onChange={handleChange} required />
                    </div>

                    {/* Booking Type */}

                    <div className="col-12">
                        <label htmlFor="bookingType" className="form-label"><i className="bi bi-list-task me-2"></i>Booking Type</label>
                        <select className="form-select" id="bookingType" name="bookingType" value={booking.bookingType} onChange={handleChange} required>
                            <option value="">Select a type</option>
                            <option value="sr">SR</option>
                            <option value="other">OTHER</option>
                            {/* Add other options as needed */}
                        </select>
                    </div>

                    <div className="col-12 text-center">
                        <button type="submit" className="btn btn-primary"><i className="bi bi-check-circle me-2"></i>Submit Booking</button>
                    </div>
                </form>
                {successMessage && <div className="alert text-center alert-success" role="alert">{successMessage}</div>}
                {errorMessage && <div className="alert text-center alert-danger" role="alert">{errorMessage}</div>}
            </div>
            <hr/>
        </>
    );
};

export default BookingPage;
