import React from 'react';
import whatsappIcon from '../../assets/img/whatsAppIcon.png';
import phoneIcon from '../../assets/img/phoneIcon_1.png';
import '../../features/features.css';

const SRCallNowButton = () => {
  return (
    <div className="sr-call-button">
      <a
        id="g_track"
        href="https://api.whatsapp.com/send?phone=919346397115&text=Hi,%20I%20would%20like%20to%20know%20more%20Details."
        className="sr-call-link"
      >
        <img
          loading="lazy"
          src={whatsappIcon}
          alt="WhatsApp Icon"
          className="sr-call-image"
        />
      </a>
      <a href="tel:8886556632" className="sr-call-link">
        <img loading="lazy" src={phoneIcon} alt="Phone Icon for call" className="sr-call-image" />
      </a>
    </div>
  );
};

export default SRCallNowButton;
