import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForCRIV = () => {
    const imagePaths = {
        criv_2: require("../assets/img/CRIV-2.png"),
        criv_3: require("../assets/img/CRIV-3.png"),
        criv_4: require("../assets/img/CRIV-4.png"),
        criv_5: require("../assets/img/CRIV-5.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Effortless Travel Starts Here: Discover the Advantages of SR Car Travels</h2>
            <div className="container">

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.criv_2}
                            alt="car rentals in vijayawada: ctig_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Friendly and Affordable</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>From our dedicated customer service representatives who assist with bookings and inquiries, to our experienced drivers committed to ensuring a safe and enjoyable journey, every team member is devoted to creating a pleasant and memorable experience for you. We emphasize open communication, attentiveness to your needs, and maintaining a warm and welcoming attitude throughout. We offer a wide range of affordable vehicles and unparalleled customer service that will make your rental experience unforgettable. Trust us for the <a href="https://srcartravels.in/sp-offers" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>best pricing</a> and service - you won't be disappointed!</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.criv_3}
                            alt="car rentals in vijayawada: criv_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Safe and Comfortable</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>At SR Car rentals in Vijayawada, we prioritize safety and comfort to ensure that every journey with us is smooth and worry-free. Our fleet of cars is meticulously maintained to the highest standards, with regular inspections and servicing for optimal performance and reliability. Our professional drivers are trained in defensive driving techniques and strictly adhere to safety protocols, prioritizing your well-being on the road. </p>

                    </div>
                </div>
                <hr style={thinLineStyle} />


                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.criv_4}
                            alt="car rentals in vijayawada: criv_4"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>24/7 Support</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>We understand the unpredictability of travel plans, which is why we provide round-the-clock support for all your car rental journeys. Our customer service team is available 24/7 to assist you with bookings, address any inquiries or concerns, and offer assistance whenever you need it. Whether it's a last-minute reservation, sudden itinerary changes, or an emergency situation on the road - our team ensures that your journey proceeds smoothly and seamlessly. </p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.criv_5}
                            alt="car rentals in vijayawada: criv_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Trusted Reputation</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Renowned for excellence, SR Car Travels is synonymous with quality in Vijayawada's transport services. By choosing us, you are partnering with a trusted name dedicated to providing exceptional travel experiences. With our commitment to superior service and unwavering standards, your journey is in professional hands at every step of the way.</p>

                    </div>
                </div>

                <hr style={thinLineStyle} />

            </div>

        </>
    );
};

export default SRTravelOptionsForCRIV;
