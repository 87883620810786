import React from 'react';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForCarRentalsInVijayawada from './FAQsComponentForCarRentalsInVijayawada';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SRTravelOptionsForCRIV from './SRTravelOptionsForCRIV';
import BookingOptionsForCRIV from './BookingOptionsForCRIV';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/CRIV-1.png"),
    require("../assets/img/CRIV-6.png"),
    require("../assets/img/CRIV-7.png"),
    require("../assets/img/CRIV-11.png"),
    require("../assets/img/CRIV-12.png"),
];

const CarRentalsInVijayawada = () => {
    return (
        <>
            <Helmet>
                <title>Car Rentals in Vijayawada | Car Rentals Vijayawada - SR Car Travels</title>
                <meta name="description" content="At SR Car rentals in Vijayawada, we prioritize safety and comfort to ensure that every journey with us is smooth and worry-free. contact us at +91-8886556632" />
                <link rel="canonical" href="https://srcartravels.in/car-rentals-in-vijayawada" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <SRCustomForm />
            <div className="stress-free-travel-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1119px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[0]}
                        alt="Car rentals in vijayawada"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h1 style={{ fontSize: '2rem' }}>Simplify Your Journey With Seamless Bookings From SR Car Rentals in Vijayawada</h1>
                    <p>Vijayawada is a city pulsating with vibrant energy, seamlessly blending ancient history and modern dynamism. Navigating its busy streets and hidden alleyways can be overwhelming for first-time visitors. Picture this: you arrive at the airport, brimming with excitement to unravel the city's rich tapestry, embracing spiritual serenity at Kanakadurga Temple, or delving into echoes of the past at Kondapalli Fort. SR Car Rentals in Vijayawada serves as your reliable travel companion, turning that vision into an effortless reality.</p>
                    <p>With SR Car Travels' seamless online booking system, you can effortlessly reserve the ideal car with just a few clicks. We have everything from stylish sedans perfect for city navigation to roomy SUVs designed for adventures beyond the urban core. Imagine yourself gliding along the Krishna Riverfront, embracing the cool breeze carrying whispers of the city's charm, or embarking on an impromptu road trip to explore Undavalli Caves—all made possible by the comfort and freedom of SR Car Rentals Vijayawada.</p>
                </div>
            </div>
            <br></br>
            <SRTravelOptionsForCRIV />
            <br></br>
            <h2 className="text-center mb-4 fw-bold fs-4">How Do You Book Car Rentals in Vijayawada With a Driver From SR Car Travels?</h2>
            <div className="stress-free-travel-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1119px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[1]}
                        alt="Car Travels in Guntur"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h4 style={{ color: "#2F4F4F" }}>Through Online Website</h4>
                    <p>One of the most convenient methods for booking car rentals in Vijayawada is through our website. Just navigate to our site and enter your trip details, and our team will promptly get in touch with you to provide further assistance.</p>
                    <h4 style={{ color: "#2F4F4F" }}>Through Contact Number</h4>
                    <p>
                        To book SR Car Travels, simply get in touch with our team at
                        <a href="tel:+918886556632" >
                            📞 +91-8886556632
                        </a>.
                        Our adept team is ready to assist you with your booking needs and provide any necessary information regarding our services.
                    </p>

                </div>
            </div>
            <div className="stress-free-travel-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1119px', margin: 'auto' }}>

                <div style={{ flex: '2', padding: '20px' }}>
                    <h4 style={{ color: "#2F4F4F" }}>Through Email</h4>
                    <p>You can also make inquiries or book our services by emailing us at <a href="contact@srcartravels.in" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>contact@srcartravels.in</a>. Our dedicated team will help you professionally plan your itinerary.</p>
                    <h4 style={{ color: "#2F4F4F" }}>Through WhatsApp</h4>
                    <p>To effortlessly book your car for the upcoming trip, simply connect with our team via WhatsApp at the handle: srdrivingschool7857, accessible on our website. Our dedicated team is ready to assist you with all your booking needs.</p>
                </div>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[2]}
                        alt="Car Travels in Guntur"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
            </div>
            <br></br>
            <BookingOptionsForCRIV />
            <br></br>
            <div className="container py-5 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#113042' }}>
                <div className="">
                    <h2 className="mb-4 fw-bold fs-4 text-white text-center">
                        The Perfect Ride For Every Journey: Explore Our Diverse Fleet
                    </h2>
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6">
                            <div className="table-responsive">
                                <table className="table table-dark table-striped" style={{ height: '300px', width: '400px', overflowY: 'auto' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Taxi Type</th>
                                            <th scope="col">Capacity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Sedan (Dezire, Etios)</td>
                                            <td><span className="badge bg-success rounded-pill">4+1</span></td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>SUV Ertiga</td>
                                            <td><span className="badge bg-success rounded-pill">6+1</span></td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Innova</td>
                                            <td><span className="badge bg-success rounded-pill">7+1</span></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <LazyLoadImage
                                alt="Taxi Options"
                                src={imagePaths[3]}
                                effect="blur"
                                className="img-fluid rounded"
                                style={{ height: '300px', width: '400px' }}
                            />
                        </div>
                    </div>
                    <p className="fw-bold fs-4 text-white">Reserve Your Car in Minutes: Call Now!</p>
                </div>
            </div>
            <br></br>
            <div className="summary-component" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1152px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[4]}
                        alt="Car rentals in vijayawada"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h2 style={{ fontSize: '2rem' }}>SR Car Travels: Your Key to a Smooth, Enjoyable Journey</h2>
                    <p>SR Car Rentals in Vijayawada offers a seamless and reliable solution for navigating the vibrant city with ease. With an effortless <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>online booking system</a>, visitors can reserve their ideal car quickly and efficiently. From stylish sedans to roomy SUVs, our fleet caters to diverse travel needs, ensuring that every journey is as comfortable as it is memorable.</p>
                    <p>With SR Car Travels, you can expect an enjoyable travel experience. Our team is committed to providing the best possible customer service and meeting your needs throughout your journey. Safety and comfort are our top priorities; our well-maintained fleet and professional drivers adhere to strict safety protocols for worry-free travel. Choose SR Car Travels for a reliable partner in effortless travel experiences.
                    </p>
                    {/* <div className="contact-info">
                        <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a></p>
                        <p><a href="mailto:contact@srcartravels.in" className="btn btn-primary">📧 contact@srcartravels.in</a></p>
                    </div>
                    <a href="https://srcartravels.in/" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                        Know More
                    </a> */}
                </div>

            </div>
            <br></br>
            <FAQsComponentForCarRentalsInVijayawada />
        </>
    );
};

export default CarRentalsInVijayawada;
