import React from 'react';
import { Helmet } from 'react-helmet';

const SpecialOffers = () => {
    const offers = [
        {
            id: 'hyderabad',
            title: 'Hyderabad',
            description: "Explore the rich history and vibrant culture of the 'City of Nizams.' Don't miss the iconic Charminar and Golconda Fort.",
            images: [
                '/assets/img/hyderabad/slide-1.png',
                '/assets/img/hyderabad/slide-2.png',
                '/assets/img/hyderabad/slide-3.png',
            ],
            price: 'Only 5000 Rupees',
            includes: [
                'No Tollgate Fees',
                'No Bata',
                'No Other Extra Hidden Charges',
            ],
            bookNowLink: 'tel:8886556632',
        },
        // Add more offers as needed
    ];

    return (
        <>
            <Helmet>
                <title>Special Offers | Car Travels in Guntur | SR Car Travels</title>
                <meta name="description" content="Are you searching for the best deals on car rentals? Look no further! At SR Car Travels, we're excited to introduce our exclusive SP-Offers in Hyderabad to Vijayawada." />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/sp-offers" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Special Offers | Car Travels in Guntur | SR Car Travels" />
                <meta property="og:description" content="Are you searching for the best deals on car rentals? Look no further! At SR Car Travels, we're excited to introduce our exclusive SP-Offers in Hyderabad to Vijayawada." />
            </Helmet>
            <h1 class="text-center">Special Offers</h1>
            <section id="special-offers" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center mb-4">
                            <h5>From</h5>
                            <p><i><a href="https://srcartravels.in/vijayawada-to-hyderabad-cabs" style={{ color: 'inherit', textDecoration: 'underline' }}><strong>Vijayawada <span className="two-way-symbol">↔</span> Hyderabad</strong></a></i></p>
                            <h4>One-Way Drop</h4>
                            <ul className="list-unstyled mt-3">
                                <li><i className="fas fa-check-circle text-success"></i> {offers[0].price}</li>
                                {offers[0].includes.map((include, index) => (
                                    <li key={index}><i className="fas fa-check-circle text-success"></i> {include}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {offers.map((offer, index) => (
                        <div key={index} className="card mb-3">
                            <div id={`${offer.id}Carousel`} className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    {offer.images.map((_, idx) => (
                                        <button key={idx} type="button" data-bs-target={`#${offer.id}Carousel`} data-bs-slide-to={idx} className={idx === 0 ? 'active' : ''} aria-current={idx === 0 ? 'true' : undefined} aria-label={`Slide ${idx + 1}`}></button>
                                    ))}
                                </div>
                                <div className="carousel-inner">
                                    {offer.images.map((image, idx) => (
                                        <div key={idx} className={`carousel-item ${idx === 0 ? 'active' : ''}`}>
                                            <img src={image} className="d-block w-100" alt={`${offer.title} slide ${idx + 1}`} loading="lazy" />
                                        </div>
                                    ))}
                                </div>
                                <a className="carousel-control-prev" href={`#${offer.id}Carousel`} role="button" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </a>
                                <a className="carousel-control-next" href={`#${offer.id}Carousel`} role="button" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </a>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">{offer.title}</h4>
                                <p className="card-text">{offer.description}</p>
                                <a href={offer.bookNowLink} className="btn btn-primary">Book Now</a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default SpecialOffers;
