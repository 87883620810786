import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForTaxiServicesInVijayawada = () => {
    const faqs = [
        {
            question: "Which is the most economical cab available in Vijayawada?",
            answer: "Our most economical cabs available in Vijayawada at present are Swift Dzire and Etios."
        },
        {
            question: "I don't have a Credit or Debit card and Net Banking as well. Can I still book a taxi?",
            answer: "Our team gives you the option to pay only a part of the total amount if you do not wish to pay the full amount while booking. The remaining amount can be paid to the chauffeur/driver by cash after the trip completion. But you will have to pay a minimum amount (Rupees 500) in order to confirm the booking."
        },
        {
            question: "How much is Vijayawada taxi fare?",
            answer: "The taxi fare is determined by factors such as the duration of the trip, the type of vehicle, and other relevant details. By providing us with your trip information, we can offer you the best price for your travel date. Rest assured that we aim to provide you with accurate and professional pricing for your journey."
        },
        {
            question: "Do you provide pick up and drop service also for the taxi journey?",
            answer: "Our designated driver will provide door-to-door service, picking you up at your location and ensuring a seamless drop-off at your preferred destination upon completion of the journey."
        },
        {
            question: "What kind of luxury cars do you offer?",
            answer: "We offer an assured SUV and Innova for your comfortable journeys in Vijayawada."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">Contact FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForTaxiServicesInVijayawada;
