import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import taxiImage from '../assets/img/csv_img_4.png'; // Import the image directly

const TaxiTypesAndCapacities = () => {
    return (
        <div className="container py-5 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#113042' }}>
            <div className="text-center">
                <h2 className="mb-4 fw-bold fs-4 text-white">
                    Choose Your Ideal Ride: Taxi Types and Capacities for Every Occasion
                </h2>
                <div className="row g-4 align-items-center">
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table table-dark table-striped" style={{ height: '300px', width: '400px', overflowY: 'auto' }}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Taxi Type</th>
                                        <th scope="col">Capacity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Sedan (Dezire, Etios)</td>
                                        <td><span className="badge bg-success rounded-pill">4+1</span></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>SUV Ertiga</td>
                                        <td><span className="badge bg-success rounded-pill">6+1</span></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Innova</td>
                                        <td><span className="badge bg-success rounded-pill">7+1</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <LazyLoadImage
                            alt="Taxi Options"
                            src={taxiImage}
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ height: '300px', width: '400px' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaxiTypesAndCapacities;
