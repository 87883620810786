import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForCIV = () => {
    const imagePaths = {
        civ_2: require("../assets/img/CIV-2.png"),
        civ_3: require("../assets/img/CIV-3.png"),
        civ_4: require("../assets/img/CIV-4.png"),
        civ_5: require("../assets/img/CIV-5.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Your Ride Awaits: Explore Our Convenient and Comfortable Cab Services</h2>
            <div className="container">

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.civ_2}
                            alt="cabs in vijayawada: civ_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Airport Transfer Services</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Our dedicated airport transfer services are tailored to meet corporate clients' specific requirements, delivering dependable and punctual transportation to and from airports. Whether catering to executives attending crucial meetings, teams traveling for business conferences, or clients arriving for corporate events, our team of professional chauffeurs and well-maintained vehicles ensure a seamless experience with an emphasis on comfort and safety.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.civ_3}
                            alt="cabs in vijayawada: civ_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Outstation Cabs</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Whether you're attending meetings, visiting clients, or exploring potential markets, our <a href="https://srcartravels.in/outstation" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>outstation cab services</a>provide seamless connectivity between different locations. Our business-focused outstation cabs in Vijayawada prioritize comfort, punctuality, and professionalism. From door-to-door service to customizable travel itineraries tailored to the specific needs of business travelers – we guarantee a productive and stress-free travel experience.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />


                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.civ_4}
                            alt="cabs in vijayawada: civ_4"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>One Way Cabs</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Ideal for executives attending meetings, conferences, or corporate events, our best cabs in Vijayawada services offer seamless conveyance from point A to point B with utmost ease and efficiency. Our professional team ensures punctual arrivals, comfortable rides, and personalized service for our esteemed corporate clients. Streamline Your Travel with Effortless Point-to-Point Service. <a href="https://srcartravels.in/sp-offers" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>Book your ride</a> for a seamless, one-way journey without the hassle of arranging a return trip.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.civ_5}
                            alt="cabs in vijayawada: civ_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Business Transfer</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Business transfer, also known as corporate or executive transfer, is a specialized service crafted to meet the distinctive needs of every business traveler. From airport transfers to intercity meetings, SR Car Travels services guarantee punctual arrivals and comfortable rides with personalized attention. It ensures that executives can navigate their business itinerary with ease and confidence in all aspects of their journey. </p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

            </div>

        </>
    );
};

export default SRTravelOptionsForCIV;
