import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet'

const DriverAttendanceForm = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState({ content: '', type: '' });

    const formatDateTimeLocal = (date) => {
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        const localISOTime = (new Date(date - timezoneOffset)).toISOString().slice(0, 16);
        return localISOTime;
    };

    const [attendance, setAttendance] = useState({
        employeeName: '',
        vehicleName: '',
        pickupDateTime: formatDateTimeLocal(new Date()),
        pickupLocation: '',
        present: ''
    });

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.username) {
            setAttendance(prevState => ({
                ...prevState,
                employeeName: user.username,
            }));
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAttendance({ ...attendance, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(attendance);
            const response = await axios.post('https://srcartravels.in/submitAttendance', JSON.stringify(attendance), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // Assuming the server sends back a success message
            setMessage({ content: 'Attendance submitted successfully', type: 'success' });
            // Wait for 3 seconds before navigating to role_driver page
            setTimeout(() => navigate('/role_driver'), 3000);

        } catch (error) {
            setMessage({ content: 'Failed to submit attendance', type: 'danger' });
            console.error('Error submitting attendance:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Driver Attendance Form - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="text-center mb-4">
                <button onClick={() => navigate('/role_driver')} className="btn btn-secondary">
                    <i className="bi bi-arrow-left-circle"></i> Back
                </button>
            </div>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <h2 className="mb-4 text-center"><i className="bi bi-calendar-check-fill"></i> Submit Attendance</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="employeeName" className="form-label"><i className="bi bi-person-fill"></i> Employee Name</label>
                                <input type="text" className="form-control" id="employeeName" name="employeeName" value={attendance.employeeName} disabled required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="vehicleName" className="form-label"><i className="bi bi-truck"></i> Vehicle Name</label>
                                <input type="text" className="form-control" id="vehicleName" name="vehicleName" value={attendance.vehicleName} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pickupDateTime" className="form-label"><i className="bi bi-clock-fill"></i> Pickup Date & Time</label>
                                <input type="datetime-local" className="form-control" id="pickupDateTime" name="pickupDateTime" value={attendance.pickupDateTime} onChange={handleChange} disabled required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pickupLocation" className="form-label"><i className="bi bi-geo-alt-fill"></i> Pickup Location</label>
                                <input type="text" className="form-control" id="pickupLocation" name="pickupLocation" value={attendance.pickupLocation} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="present" className="form-label"><i className="bi bi-check-circle-fill"></i> Present</label>
                                <select className="form-select" id="present" name="present" value={attendance.present} onChange={handleChange} required>
                                    <option value="">Select...</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary"><i className="bi bi-box-arrow-in-up-right"></i> Submit</button>
                            </div>
                        </form>
                        <div className="text-center">
                            {message.content && (
                                <div className={`alert alert-${message.type} d-flex justify-content-center`}>
                                    {message.content}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DriverAttendanceForm;
