import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RentACarComponent = () => {
    const imagePath = require("../assets/img/g_img_6.png"); // Update the path to your actual image location

    return (
        <div className="container py-5">
            <div className="row align-items-center">
                {/* Image column */}
                <div className="col-md-4 mb-4 mb-md-0">
                    <LazyLoadImage
                        alt="Rent a Car in Guntur"
                        src={imagePath}
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ width: '350px', height: '300px' }}
                    />
                </div>
                {/* Content column */}
                <div className="col-md-8">
                    <h4 className="mb-2 fs-4">Rent a Car in Guntur For Day Based Rentals and Outstation Travel</h4>
                    <p className='fs-6'>Experience the convenience of renting an SR Travels cab with a professional driver for both local and outstation travel in Guntur. SR Travels offers a range of <a href='https://srcartravels.in/taxi-from-hyderabad-to-vijayawada'>taxi services</a>, including one-way outstation drops, roundtrips, and day-based hourly rentals within and around Guntur.</p>
                    <p className="mb-2 fs-6">Whether it's for a vacation, business trip, or flexible outstation packages, you can easily book cabs for single or multiple days tailored to your specific travel needs. Discover the unparalleled flexibility of the best car travels in Guntur, tailored for customized package tour itineraries or day trips, guaranteeing a smooth and hassle-free travel experience for all your excursions.</p>
                    <div className="mb-2 fs-6">
                        {/* Using Bootstrap Icons with clickable actions */}
                        <a href="tel:+918886556632" className="me-3" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <i className="bi bi-telephone-fill"></i><strong>+91-8886556632</strong>
                        </a>
                        <a href="mailto:contact@srcartravels.in" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <i className="bi bi-envelope-fill"></i> <strong>contact@srcartravels.in</strong>
                        </a>
                    </div>
                    {/* Custom styled button with spacing */}
                    <div className="mt-3">
                        <Button
                            variant="primary"
                            href="https://srcartravels.in/"
                            style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                        >
                            Know More
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RentACarComponent;
