import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForCarTravelsInGuntur = () => {
    const faqs = [
        {
            question: "Can I hire a car for a specific duration or on an hourly basis for traveling within Guntur city?",
            answer: "Yes, SR Car Travels provides flexible booking options that cater to your specific travel needs and preferences within Guntur city. You can hire a car for a particular duration or on an hourly basis, ensuring convenience and tailored service according to your requirements."
        },
        {
            question: "Are the drivers of SR Car Travels in Guntur experienced and licensed?",
            answer: "All drivers at SR Car Travels in Guntur possess extensive experience and valid licenses and participate in ongoing training to guarantee safe and dependable transportation services for our valued customers."
        },
        {
            question: "How can I book a car for traveling within Guntur city with SR Car Travels?",
            answer: "Booking a car with SR Car Travels in Guntur is hassle-free. You have the convenience of booking online through our website and also have the option to make a reservation by calling our experienced customer service team."
        },
        {
            question: "Do SR Car Travels within Guntur city cover any specific routes or destinations?",
            answer: "SR Car Travels provides comprehensive coverage of all major areas, landmarks within Guntur city, and nearby attractions and suburbs. Our skilled drivers possess a deep familiarity with local routes, enabling them to transport you seamlessly to your desired destination anywhere within the city limits."
        },
        {
            question: "What are the payment options available for hiring a car with SR Car Travels in Guntur?",
            answer: "SR Car Travels offers the convenience of multiple payment methods, including cash, credit/debit cards, and online payment platforms. This allows you to choose the option that best suits your preferences and needs for a seamless transaction experience."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForCarTravelsInGuntur;
