import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import bannerImage from '../../Banner/logo_original.png';
import '../../features/features.css';

function SRHeader() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <>
      <div className="sticky-top">
        <nav className={`navbar navbar-expand-lg navbar-dark custom-navbar-bg navbar-custom`} style={{ padding: 0 }}>
          <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Link className="navbar-brand" to="/" onClick={handleNavCollapse} style={{ marginLeft: 0, padding: 0 }}>
              <img loading="lazy" src={bannerImage} alt="SR Car Travels" className="d-inline-block" style={{ height: '75px', width: '320px' }} />
            </Link>
            <button className="navbar-toggler" type="button" onClick={handleNavCollapse}
              aria-controls="navbarNav" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
              <span className="menu-label">Menu</span>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`navbar-collapse justify-content-end ${isNavCollapsed ? 'collapse' : ''}`}
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/vijayawada-city-tour">
                    Vijayawada City Tour
                  </NavLink>
                </li>
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/pancharama-kshetras">
                    Pancharama-Kshetras
                  </NavLink>
                </li>
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/outstation">
                    OutStation
                  </NavLink>
                </li>
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/sp-offers">
                    Special Offers
                  </NavLink>
                </li>
                <li className="nav-item me-2" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/login">
                    Login
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>
        </nav>

        {/* <hr className="rounded" /> */}
      </div>
      <div style={{padding: 5}} className={`text-end me-1 ${isNavCollapsed ? 'hidden' : ''}`}>
        <a className="d-block mb-0 fw-bold text-purple" href="tel:+918886556632" style={{ textDecoration: 'none', fontSize: '1.2rem' }}>
          CALL US: (+91) 8886556632
        </a>
        <span className="d-block text-red">24/7 EVERY DAY</span>
      </div>
    </>
  );
}

export default SRHeader;
