import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForLongDriveCarsVij = () => {
    const faqs = [
        {
            question: "What are the requirements for renting a rental car in Vijayawada?",
            answer: "To rent a luxurious car from Long Drive Cars, individuals must meet the following criteria: be at least 21 yrs old, possess a valid driver's license, and provide their Aadhaar Card for verification."
        },
        {
            question: "How do I make the booking for car rental?",
            answer: "You can book the car rentals from our website at your convenience, and kindly contact us at 8886556632 for further assistance."
        },
        {
            question: "Can I extend/modify/cancel the car rental booking?",
            answer: "Yes, extensions for your booking are indeed possible, subject to availability and additional charges. Please note that any cancellations or modifications will incur nominal charges in accordance with our policy."
        },
        {
            question: "What if I damage the car accident?",
            answer: "Before renting our cars to customers, our rental agents conduct a thorough inspection to ensure their proper condition. Upon returning the vehicle, we carefully inspect for any damages, scratches, or paint chips. If any such damages are found to have been caused during your rental, we charge for the necessary repairs."
        },
        {
            question: "Do you also rent your car for Vijayawada sightseeing?",
            answer: "Experience every hidden gem of Vijayawada in luxury and comfort with our local sightseeing services. From the magnificent Kanaka Durga Temple to the tranquil Undavalli Caves, embark on an unforgettable journey with us."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">Rental FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForLongDriveCarsVij;
