import React, { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Importing LazyLoadImage component
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: Importing CSS for lazy load blur effect

// Assuming the images are imported at the top level for ease of reading
import sedanImg from '../../assets/img/tDzire1.png';
import suvImg from '../../assets/img/tErtiga1.png';
import innovaImg from '../../assets/img/tInnova.png';

const SRSubmissionResults = ({ data }) => {
    const razorpayFormRef = useRef(null);
    const scriptLoaded = useRef(false);

    useEffect(() => {
        if (data && razorpayFormRef.current && !scriptLoaded.current) {
            const script = document.createElement('script');
            script.src = "https://checkout.razorpay.com/v1/payment-button.js";
            script.async = true;
            script.setAttribute('data-payment_button_id', 'pl_NEsP2iXg3H1RBj');
            razorpayFormRef.current.appendChild(script);

            // Mark script as loaded
            scriptLoaded.current = true;

            // Cleanup function to remove the script if the component unmounts
            return () => {
                if (razorpayFormRef.current) {
                    razorpayFormRef.current.removeChild(script);
                }
            };
        }
    }, [data]); // Ensure this effect runs only when data is loaded

    if (!data) return null; // Or a loading spinner

    const vehicleDetails = [
        {
            type: "Sedan",
            img: sedanImg,
            includedKm: data.dMaxIncludedKm,
            price: data.dPrice,
            beta: data.dDBeta,
            extraFarePerKm: "Rs.12/KM",
        },
        {
            type: "SUV (Ertiga)",
            img: suvImg,
            includedKm: data.eMaxIncludedKm,
            price: data.ePrice,
            beta: data.dEBeta,
            extraFarePerKm: "Rs.15/KM",
        },
        {
            type: "Innova/Crysta",
            img: innovaImg,
            includedKm: data.iMaxIncludedKm,
            price: data.iPrice,
            beta: data.dIBeta,
            extraFarePerKm: "Rs.16/KM",
        }
    ];

    return (
        <div className="container mt-4">
            <div className="card-group">
                {vehicleDetails.map((vehicle, index) => (
                    <div className="card" key={index}>
                        <LazyLoadImage
                            effect="blur"
                            src={vehicle.img}
                            className="card-img-top"
                            alt={`${vehicle.type} Image`}
                            height="auto"
                            width="100%"
                        />
                        <div className="card-body text-center text-white" style={{ backgroundColor: '#173141', borderColor: '#ccc' }}>
                            <h4 className="card-title">{vehicle.type}</h4>
                            <p className="card-text">Included KM - {vehicle.includedKm} KM</p>
                            <p className="card-text">Extra fare/Km - {vehicle.extraFarePerKm}</p>
                            <p className="card-text">Driver Beta(Charges) - {vehicle.beta}</p>
                            <p className="card-text">Parking(If Any) Extra</p>
                            <hr />
                            <p className="card-text">Price : Rs.{vehicle.price}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center mt-4 bg-dark text-white p-4">
                <p>
                    <span role="img" aria-label="Celebration">🎉</span> Exclusive Offer Just for You! <span role="img" aria-label="Gift">🎁</span><br />
                    Click '<strong>Pay Now</strong>' to book and get <strong>10% cash back</strong>! Secure your spot with a 500 advance payment and enjoy the ride with a bonus!<br />
                    Hurry, this limited-time offer won't last! <span role="img" aria-label="Rocket and Sparkles">🚀✨</span>
                </p>
                <div className="d-flex justify-content-center gap-2">
                    <a href="tel:8886556632" className="btn btn-primary" style={{ width: '150px', height: '40px', lineHeight: '1.5' }}>Call Now</a>
                    {/* The form ref here is a placeholder for the Razorpay button which will be dynamically inserted */}
                    <form ref={razorpayFormRef} className="d-inline-block"></form>
                </div>
            </div>
        </div>
    );
};

export default SRSubmissionResults;
