// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import MyRootApp from './pages/MyRootApp';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MyRootApp />
);

reportWebVitals();
