import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const SRFAQs = () => {

  const faqs = [
    {
      question: "How do I make a booking?",
      answer: "Visit the SR Car Travels website and call us at 8886556632."
    },
    {
      question: "What types of vehicles do you offer?",
      answer: "Sedans, SUVs, Luxury Cars, Specialty Vehicles. Each suited for different needs and preferences."
    },
    {
      question: "What are your rates and pricing options?",
      answer: "We offer hourly rates, daily rates, additional fees for extra services, and custom packages. Please call us for details."
    },
    {
      question: "Do you offer airport transfers?",
      answer: "Yes, with timely pickup, luggage assistance, flight tracking, and comfortable rides."
    },
    {
      question: "Are your drivers licensed and experienced?",
      answer: "Absolutely, they hold valid licenses, have extensive experience, and are trained in customer service and safety."
    },
    {
      question: "Is there a minimum or maximum rental duration?",
      answer: "We offer flexible rental durations, from a few hours to as long as needed."
    },
    {
      question: "What is your cancellation policy?",
      answer: "Cancellations can be made any time before the scheduled pickup without any fees under reasonable circumstances."
    },
    {
      question: "Do you provide car seats for children?",
      answer: "Yes, upon request and with various options based on age and weight."
    },
    {
      question: "Can I choose a specific vehicle model or color?",
      answer: "We allow preferences for models and strive to accommodate color choices subject to availability."
    },
    {
      question: "Is there a waiting time policy?",
      answer: "We offer a complimentary waiting time period as part of your booking for some flexibility. Additional waiting time is subject to charges."
    },
    {
      question: "How do I pay for my booking?",
      answer: "We accept major credit cards, online payments, and cash payments directly to our drivers at the time of service."
    },
    {
      question: "Is there a limit on the number of passengers or luggage?",
      answer: "The maximum number of passengers and luggage limits depend on the vehicle model. Please discuss any specific requirements with our team."
    },
    {
      question: "Do you offer special packages for events or occasions?",
      answer: "Yes, we offer customized packages and discounts for events such as weddings, corporate outings, and sightseeing tours."
    },
    {
      question: "What safety measures do you have in place?",
      answer: "We prioritize passenger safety with regular vehicle maintenance, thorough driver background checks, and strict adherence to traffic regulations."
    },
    {
      question: "How far in advance should I book?",
      answer: "We recommend booking well in advance to secure your preferred date and time, especially during peak seasons or for special events."
    },
    {
      question: "Can I request a specific pickup and drop-off location?",
      answer: "Yes, you can specify custom pickup and drop-off locations when making your booking."
    },
    {
      question: "What happens if my flight is delayed?",
      answer: "We track flight arrivals and adjust pickup times accordingly to ensure we're there when you arrive."
    },
    {
      question: "How can I contact customer support?",
      answer: "Reach out to our customer support team via phone at 8886556632 or email at contact@srcartravels.in."
    },
    {
      question: "Do you offer corporate or business accounts?",
      answer: "Yes, we provide special arrangements and discounts for corporate clients and frequent travelers."
    },
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
        <h2 className="mb-4 text-center">FAQs</h2>
        <Accordion defaultActiveKey="0">
          {faqs.map((faq, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default SRFAQs;
