import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Import your icons
import driverIcon from '../../assets/img/driverIcon.png';
import supportIcon from '../../assets/img/support.png';
import hiddenChargesIcon from '../../assets/img/hidden.png';
import wideCarIcon from '../../assets/img/wideCar.png';

const SRInfoServices = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {/* Example Service: Courteous Drivers */}
        <div className="col-md-6 col-sm-6 col-lg-6 mb-4 text-center">
          <div className="service-box">
            <LazyLoadImage
              alt="Courteous professional driver"
              src={driverIcon}
              effect="blur"
              className="img-fluid rounded-circle mb-3 service-icon"
            />
            <h3>Courteous Drivers</h3>
            <p>SR Car Travels has professional and courteous drivers who are polite and respectful, ensuring a positive driving culture.</p>
          </div>
        </div>

        {/* Service 2: 24x7 Online Support */}
        <div className="col-md-6 col-sm-6 col-lg-6 mb-4 text-center">
          <div className="service-box">
            <LazyLoadImage
              alt="24/7 online customer support"
              src={supportIcon}
              effect="blur"
              className="img-fluid rounded-circle mb-3 service-icon"
            />
            <h3>24x7 Online Support</h3>
            <p>SR Car Travels provides 24/7 customer support through various channels, aiming to improve the overall customer experience.</p>
          </div>
        </div>

        {/* Service 3: No Hidden Charges */}
        <div className="col-md-6 col-sm-6 col-lg-6 mb-4 text-center">
          <div className="service-box">
            <LazyLoadImage
              alt="Transparent pricing with no hidden charges"
              src={hiddenChargesIcon}
              effect="blur"
              className="img-fluid rounded-circle mb-3 service-icon"
            />
            <h3>No Hidden Charges</h3>
            <p>SR Car Travels ensures transparency with no hidden or unexpected charges, building trust with our customers.</p>
          </div>
        </div>

        {/* Service 4: Wide Car Rental Inventory */}
        <div className="col-md-6 col-sm-6 col-lg-6 mb-4 text-center">
          <div className="service-box">
            <LazyLoadImage
              alt="Wide range of rental cars"
              src={wideCarIcon}
              effect="blur"
              className="img-fluid rounded-circle mb-3 service-icon"
            />
            <h3>Wide Car Rental Inventory</h3>
            <p>We offer a diverse range of cars for all needs, from compact hatchbacks to luxury cars, ensuring comfort and style.</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default SRInfoServices;
