import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BestTimeForRentingCar = () => {
    // Correct the image path according to your project structure
    const imagePath = require('../assets/img/g_img_5.png');

    return (
        <div className="container my-2 py-3" style={{ backgroundColor: '#113042' }}> {/* Added padding for vertical alignment */}
            <div className="row justify-content-center align-items-center"> {/* Ensure full viewport height and center content */}
                <div className="col-10 col-sm-8 col-md-6"> {/* Adjust column size for better alignment */}
                    {/* Text content */}
                    <div className="text-center text-md-start mb-4">
                        <h3 className="text-white mb-3 fs-5">Best Time For Renting a Car in Guntur</h3>
                        <p style={{ color: '#E1E1E1' }} fs-6>Guntur experiences a maritime climate year-round, making it an ideal destination for visitors at any time. Nonetheless, the period from September to February attracts the highest tourist influx due to the particularly pleasant weather during the winter season. This makes these months the optimal time for tourists to experience the city's charm.</p>
                        <div className="d-flex justify-content-center justify-content-md-start mt-2">
                            <Button href="https://srcartravels.in/" style={{ backgroundColor: 'green', borderColor: 'green' }}>Know More</Button>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-sm-8 col-md-4"> {/* Adjust column size for better alignment */}
                    {/* Image */}
                    <LazyLoadImage
                        alt="Best Time For Renting a Car in Guntur"
                        src={imagePath}
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ maxWidth: '100%', height: 'auto' }} // Ensure the image is responsive
                    />
                </div>
            </div>
        </div>
    );
};

export default BestTimeForRentingCar;
