import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AttendanceComponent = () => {
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAttendanceRecords = async () => {
            try {
                // Retrieve the user data from session storage
                const userData = JSON.parse(sessionStorage.getItem('userData'));
                console.log(userData);
                // Check if userData exists and has a role
                if (userData && userData.role) {
                    const response = await axios.get(`https://srcartravels.in/attendances/${userData.role}`);
                    setAttendanceRecords(response.data);
                } else {
                    // If no user data or role, navigate to login
                    navigate('/login');
                }
            } catch (error) {
                console.error('Failed to fetch attendance records:', error);
            }
        };

        fetchAttendanceRecords();
    }, [navigate]);

    // Function to handle the Back button click
    const handleBackClick = () => {
        navigate('/role_owner'); // Navigate to the role_owner page
    };

    return (
        <>
            <Helmet>
                <title>Attendance Records - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container mt-5">
                <h2 className="mb-4 text-center">Attendance Records</h2>
                <div className="text-center mt-4">
                    <button className="btn btn-secondary" onClick={handleBackClick}>
                        <i className="bi bi-arrow-left"></i> Back
                    </button>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Employee Name</th>
                                <th scope="col">Vehicle Name</th>
                                <th scope="col">Pickup DateTime</th>
                                <th scope="col">Pickup Location</th>
                                <th scope="col">Present</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceRecords.map((record, index) => (
                                <tr key={record.id}>
                                    <td>{index + 1}</td>
                                    <td>{record.employeeName}</td>
                                    <td>{record.vehicleName}</td>
                                    <td>{new Date(record.pickupDateTime).toLocaleString()}</td>
                                    <td>{record.pickupLocation}</td>
                                    <td>{record.present}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AttendanceComponent;
