import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: for blur effect

// Assuming these paths are correct relative to this component file
const imagePaths = {
    bookingImg: require("../assets/img/booking.png"),
    rupeeImg: require("../assets/img/rupee_img.png"),
    driverImg: require("../assets/img/driver_img.png"),
    onlineSupportImg: require("../assets/img/online_support_img.png"),
    sanitisedImg: require("../assets/img/sanitised_img.png"),
    inventoryImg: require("../assets/img/inventory_img.png")
};

const FeatureCard = ({ title, text, imgSrc, link }) => (
    <div className="card" style={{ width: '18rem' }}>
        <LazyLoadImage
            alt={title}
            src={imgSrc} // Use imported image path
            effect="blur" // Optional: blur effect while loading
            wrapperClassName="card-img-top"
        />
        <div className="card-body">
            <h3 className="card-title">{title}</h3>
            <p className="card-text">{text}</p>
            {link && <a href={link} className="stretched-link"></a>}
        </div>
    </div>
);

const SRTravelFeatures = () => {
    const features = [
        {
            imgSrc: imagePaths.bookingImg,
            title: "Convenient & Easy Booking",
            text: (
                <>
                    Our car booking platform offers a seamless user experience, enabling you to effortlessly book a cab in Guntur with just a few clicks. You can bid farewell to prolonged wait times and long queues, as our user-friendly platform ensures a swift and convenient <a href="https://srcartravels.in/" >booking process</a>.
                </>
            ),
            link: "https://srcartravels.in/"
        },
        {
            imgSrc: imagePaths.rupeeImg,
            title: "Affordable Fares",
            text: "We recognize the significance of your investment, and our taxi/car fares are tailored to provide cost-effective solutions. You can secure your cab with a 500 advance payment and benefit from additional perks during your ride. Experience an economical journey without sacrificing quality, comfort, and safety.",
        },
        {
            imgSrc: imagePaths.driverImg,
            title: "Courteous & Friendly Drivers",
            text: (
                <>
                    SR Car Travels prides itself on providing a superior driving experience with our team of professional and courteous drivers. <a href="https://srcartravels.in/login">Our drivers</a> embody politeness and respect, fostering a positive driving culture for your comfort and safety.
                </>
            ),
            link: "https://srcartravels.in/login"
        },
        {
            imgSrc: imagePaths.onlineSupportImg,
            title: "24X7 Continuous Online Support",
            text: "We strive to enhance the complete customer experience by providing round-the-clock support through various channels. Our commitment to 24/7 availability ensures that customers can seek assistance at any time, demonstrating our dedication to delivering the best service.",
        },
        {
            imgSrc: imagePaths.sanitisedImg,
            title: "Safe and Well-Sanitized Cars",
            text: "The safety of our passengers is our top priority. We sanitize and air our cabs before and after every ride to ensure a clean and safe environment. Additionally, our drivers undergo extensive training in vehicular and personal hygiene practices, aligning with the guidelines issued by the WHO. Rest assured, your well-being is in capable hands.",
        },
        {
            imgSrc: imagePaths.inventoryImg,
            title: "Diverse Car Rental Inventory",
            text: "Our extensive selection of vehicles caters to a wide range of preferences and requirements, encompassing everything from compact cars to luxurious models. This ensures that we can accommodate the diverse needs of our customers, delivering both comfort and sophistication.",
        }
    ];

    return (
        <div className="container py-2">
            <h2 className="text-center fs-4 mb-4">Why Choose SR Car Travels For Your Next Journey in Guntur?</h2>
            <div className="row g-4">
                {features.map((feature, index) => (
                    <div className="col-md-6 col-lg-4" key={index}>
                        <div className="card h-100">
                            <div className="card-img-top d-flex justify-content-center align-items-center">
                                <LazyLoadImage
                                    alt={feature.title}
                                    src={feature.imgSrc} // Use imported image path
                                    effect="blur" // Optional: blur effect while loading
                                    wrapperClassName="card-img-top"
                                />
                            </div>
                            <div className="card-body">
                                <h3 className="card-title fs-5">{feature.title}</h3>
                                <p className="card-text fs-6">{feature.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SRTravelFeatures;
