import React from 'react';
const SRCustomerReviews = () => {
  return (
    <div>
      {/* SEO-Friendly Tags */}
      <title>Customer Reviews - SR Car Travels</title>
      <meta
        name="description"
        content="Read customer reviews for SR Car Travels. Our customers share their experiences and satisfaction with our services."
      />

      <h2 className="text-center mb-4">
        <b>What Our Customers Say</b>
      </h2>

      <div className="container my-5">
        <div className="row">
          {/* Review 1 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0">Ariella Krupa Joy</h4>
                <p className="mb-0">
                  <small className="text-muted">4.5/5 stars</small>
                </p>
              </div>
              <div className="card-body">
                <p className="card-text">
                  "I recently used SR Car Travels for my trip, and I must say, I
                  was extremely impressed with their service. The car was clean
                  and well-maintained, and the driver was professional,
                  courteous, and punctual. The ride was smooth and comfortable,
                  and I reached my destination on time without any hassle. The
                  booking process was also very easy and hassle-free. I highly
                  recommend SR Car Travels to anyone looking for a reliable and
                  comfortable travel experience."
                </p><br />
              </div>
            </div>
          </div>

          {/* Review 2 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0">Akshay Kumar</h4>
                <p className="mb-0">
                  <small className="text-muted">5/5 stars</small>
                </p>
              </div>
              <div className="card-body">
                <p className="card-text">
                  "I have been using SR Car Travels for my business trips for
                  the past few months, and I can confidently say that they
                  provide top-notch service. The cars are always clean and
                  well-maintained, and the drivers are very professional and
                  knowledgeable about the routes. They always ensure that I
                  reach my destination on time, and the booking process is very
                  easy and hassle-free. I highly recommend SR Car Travels to
                  anyone looking for a reliable and comfortable travel
                  experience."
                </p>
              </div>
            </div>
          </div>

          {/* Review 3 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0">Hari Babu</h4>
                <p className="mb-0">
                  <small className="text-muted">5/5 stars</small>
                </p>
              </div>
              <div className="card-body">
                <p className="card-text">
                  "I had a great experience with SR Car Travels during my recent
                  vacation. The car was comfortable, spacious, and
                  well-equipped, and the driver was very friendly and helpful.
                  He knew the area well and suggested some great local places
                  to visit. The service was prompt and reliable, and the prices
                  were very reasonable. I would definitely use SR Car Travels
                  again and highly recommend them to anyone looking for a great
                  travel experience."
                </p><br />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p>
            For more reviews, visit our{' '}
            <a
              href="https://maps.app.goo.gl/aBFRpKoBWGCm2MVd7"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Maps page
              <br />
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default SRCustomerReviews;
