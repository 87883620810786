import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const RidesDuesComponent = () => {
    const navigate = useNavigate();
    const [ridesDues, setRidesDues] = useState(null); // Use state to handle ridesDues data

    useEffect(() => {
        // Attempt to retrieve userData from sessionStorage
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        // Navigate to login if userData is null or does not exist
        if (!userData) {
            navigate('/login');
        } else {
            // If userData exists, set ridesDues from userData
            setRidesDues(userData.ridesDues);
        }
    }, [navigate]);

    // If ridesDues is null (no userData found or userData does not contain ridesDues), return null or a loading indicator
    if (!ridesDues) {
        // Optionally, show a loading indicator or a message here
        return null;
    }

    const sortedRidesDues = ridesDues.sort((a, b) => new Date(b.pickupDateTime) - new Date(a.pickupDateTime));

    return (
        <>
            <Helmet>
                <title>Ride Dues - SR Car Travels</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container my-4">
                {/* Services Section */}
                <div className="row g-3 justify-content-center text-center">
                    <div className="col-6 col-sm-6 col-md-3">
                        <button className="btn btn-primary w-100" onClick={() => navigate('/booking')}>
                            <i className="bi bi-journal-plus me-2"></i> Booking
                        </button>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <button className="btn btn-secondary w-100" onClick={() => navigate('/attendances')}>
                            <i className="bi bi-calendar-check me-2"></i> Attendances
                        </button>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <button className="btn btn-danger w-100" onClick={() => navigate('/apply_leave')}>
                            <i className="bi bi-box-arrow-in-right me-2"></i> Apply Leave
                        </button>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <button className="btn btn-warning w-100" onClick={() => navigate('/driver_leaves')}>
                            <i className="bi bi-box-arrow-in-right me-2"></i> Driver Leaves
                        </button>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <button className="btn btn-info w-100" onClick={() => navigate('/add_driver')}>
                            <i className="bi bi-plus-circle me-2"></i> Add Driver
                        </button>
                    </div>
                </div>
                <hr className="rounded" />
                {/* Ride Dues Records Section */}
                <h2 className="mb-4 text-center custom-title">Ride Dues</h2>
                <div className="table-responsive">
                    <table className="table table-striped table-hover align-middle">
                        <thead>
                            <tr>
                                <th scope="col">Ride Date</th>
                                <th scope="col">Booking Name</th>
                                <th scope="col">Driver</th>
                                <th scope="col">Pickup Location</th>
                                <th scope="col">Dropoff Location</th>
                                <th scope="col">Total Cost</th>
                                <th scope="col">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedRidesDues.map((ride) => (
                                <tr key={ride.id}>
                                    <td>{ride.pickupDateTime}</td>
                                    <td>{ride.bookingName}</td>
                                    <td>{ride.assignDriver}</td>
                                    <td>{ride.pickupLocation}</td>
                                    <td>{ride.dropoffLocation}</td>
                                    <td>{ride.totalCost}</td>
                                    <td>{ride.balance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default RidesDuesComponent;
