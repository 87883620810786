import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const VijayawadaCarRentalsExperience = () => {
    // Update the path as per your project structure
    const imagePath = require("../assets/img/g_frame_1.png");

    return (
        <div className="container py-5">
            <div className="row align-items-center">

                {/* Content column */}
                <div className="col-md-8 mb-2">
                    <div className="fs-5 fw-bold mb-2">Discover Vijayawada with Premium Car Rentals from SR Car Travels</div>
                    <p className="fs-6">Navigating the vibrant streets of Vijayawada has never been easier, thanks to SR Car Travels' premium car rental services. We're here to transform your travel experiences, offering you a blend of luxury, comfort, and affordability.</p>
                    <p className="fs-6">Our diverse fleet ensures that whether you're in Vijayawada for business or leisure, you'll find the perfect vehicle to suit your needs. From sleek sedans for solo travelers to spacious SUVs for family trips, SR Car Travels caters to every requirement, making us the go-to choice for car rentals in Vijayawada.</p>
                    <p className="fs-6">Experience the ease of booking with SR Car Travels, where our transparent pricing and dedicated customer service ensure a hassle-free journey from start to finish. Choose us for your next journey in Vijayawada and enjoy the peace of mind that comes with a reliable car rental service.</p>
                </div>

                {/* Image column */}
                <div className="col-md-4">
                    <LazyLoadImage
                        src={imagePath}
                        alt="Car Rentals in Vijayawada"
                        effect="blur"
                        width="100%"
                        height="auto"
                        style={{ height: "300px", width: "350px", borderRadius: "20px" }}
                        className="img-fluid rounded"
                    />
                </div>
            </div>
        </div>
    );
};

export default VijayawadaCarRentalsExperience;
