import React from 'react';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForCabsInVijayawada from './FAQsComponentForCabsInVijayawada';
import SRTravelOptionsForCIV from './SRTravelOptionsForCIV';
import BookingOptionsForCIV from './BookingOptionsForCIV';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/CIV-1.png"),
    require("../assets/img/CIV-9.png"),
    require("../assets/img/CIV-10.png"),
];

const CabsInVijayawada = () => {
    return (
        <>
            <Helmet>
                <title>Cabs in Vijayawada | Best Cabs in Vijayawada - SR Car Travels</title>
                <meta name="description" content="SR Car Travels is the best cabs in Vijayawada services offer seamless conveyance from point A to point B with utmost ease and efficiency." />
                <link rel="canonical" href="https://srcartravels.in/cabs-in-vijayawada" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <SRCustomForm />
            <div className="stress-free-travel-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1119px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[0]}
                        alt="Cabs in Car"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h1 style={{ fontSize: '2rem' }}>Make Business Travel Effortless with SR Car Travels Cabs in Vijayawada</h1>
                    <p>When it comes to embarking on a business trip, time is of the essence. Navigating unfamiliar streets and dealing with transportation logistics can be daunting tasks that eat into your valuable schedule. This is where <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>SR Car Travels</a> steps in to alleviate the burden, ensuring that your focus remains on maximizing productivity and making impactful business connections during your visit to Vijayawada. With our reliable cabs in Vijayawada, you can bid farewell to transportation-related stress and concentrate wholeheartedly on sealing deals while leaving an enduring impression. </p>
                </div>
            </div>
            <br></br>
            <SRTravelOptionsForCIV/>
            <br></br>
            <BookingOptionsForCIV/>
            <br></br>
            <div className="rental-service-section" style={{ backgroundColor: '#eef4f7', padding: '40px', borderRadius: '15px', maxWidth: '1171px', margin: 'auto' }}>
                <div className="row">
                    <div className="col-md-8">
                        <h3>Experienced & Courteous Drivers</h3>
                        <p>At SR Car Travels, we take great pride in our team of highly experienced and courteous drivers. They are deeply committed to delivering exceptional service and strive to ensure the utmost comfort and satisfaction for all our customers. Prioritizing professionalism and safety, each driver undergoes rigorous training along with comprehensive background checks to meet our high standards. Our drivers leverage years of experience navigating local routes and traffic patterns, ensuring efficient travel on any journey. </p>
                        
                        <a
                            className="btn btn-primary"
                            style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                            href="https://srcartravels.in/sp-offers"
                        >
                            Know More
                        </a>
                    </div>
                    <div className="col-md-4">
                        <LazyLoadImage
                            src={imagePaths[1]}
                            alt="SR Car Travels"
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>
                </div>
            </div>
            <br></br>

            <div className="summary-component" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1152px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[2]}
                        alt="Summing It Up"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h3>Stress-Free Business Journeys Start Here: SR Car Travels - Your Go-To Car Rental</h3>
                    <p>Elevate your upcoming <a href="https://srcartravels.in/car-travels-vijayawada" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>business trip in Vijayawada</a> with the reliable and stress-free car rentals offered by SR Car Travels. We understand the demands of a busy business schedule, and our unwavering commitment to punctuality and professionalism ensures that you arrive at every meeting feeling confident and prepared. Say goodbye to the tedious task of navigating unfamiliar roads or hailing cabs in Vijayawada. Our diverse fleet of meticulously maintained vehicles caters to all your business needs, whether you require a sleek sedan for a client meeting or a spacious SUV for a team outing. All our cars are chauffeured by experienced and courteous drivers with extensive knowledge of Vijayawada's landscape, ensuring an efficient journey without hiccups.</p>
                    <p>At SR Car Travels, we prioritize your productivity and comfort. Book your car rental online effortlessly in just a few clicks, and let us take care of all transportation logistics while you focus on closing deals and making lasting impressions during this seamless and stress-free <a href="https://srcartravels.in/vijayawada-city-tour" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>business travel experience in Vijayawada</a>.</p>
                    <p>Choose SR Car Travels for a seamless travel experience that enhances your overall business visit agenda.</p>
                    <div className="contact-info">
                        <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a></p>
                        <p><a href="mailto:contact@srcartravels.in" className="btn btn-primary">📧 contact@srcartravels.in</a></p>
                    </div>

                    <a href="https://srcartravels.in/" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                        Know More
                    </a>
                </div>
            </div>
            <br></br>

            <FAQsComponentForCabsInVijayawada />
        </>
    );
};

export default CabsInVijayawada;
