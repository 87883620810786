import React, { useRef } from 'react';
import useOnScreen from './UseOnScreen'; // Assuming useOnScreen is saved in the same directory

const SrCarTravelsMap = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div className="container-fluid"><br />
      <h3 className="text-center">SR Car Travels Map Address</h3>
      <div className="row" ref={ref}>
        {isVisible && (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d61207.51335938589!2d80.6298875!3d16.5023742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fb8e656cf61d%3A0xe4d4203c2e9acc69!2sSR%20Car%20Travels!5e0!3m2!1sen!2sin!4v1721910449401!5m2!1sen!2sin"
            width="100%"
            height="320"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            title="SR Car Travels In Vijayawada"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default SrCarTravelsMap;
