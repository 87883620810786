import React from 'react';
import { Helmet } from 'react-helmet';

const SRPrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | SR Car Travels</title>
                <meta name="description" content="Check out the privacy policy in details about SR Car Travels - car rental services for outstations, city travel & tour. If you have any questions about this Privacy Policy, You can contact us." />
                <link rel="canonical" href="https://srcartravels.in/privacy-policy" />
                <meta name="robots" content="index, follow" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Privacy Policy | SR Car Travels" />
                <meta property="og:description" content="Check out the privacy policy in details about SR Car Travels - car rental services for outstations, city travel & tour. If you have any questions about this Privacy Policy, You can contact us." />
            </Helmet>
            <div className="container my-5">
                <h1 className="text-center mb-4">Privacy Policy for SR Car Travels</h1>
                <div className="border-top border-2"></div>
                <h2 className="mt-4 styled-h1">Privacy Policy</h2> {/* Changed to h2 but styled as h1 */}
                <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
                <h2 className="styled-h1">Interpretation and Definitions</h2> {/* Changed to h2 but styled as h1 */}
                <h3>Interpretation</h3> {/* Consider using h3 for sub-sections if appropriate */}
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                {/* Additional Content: Charges and Fees */}
                <h2 className="mt-5 styled-h1">Additional Charges and Fees</h2> {/* Changed to h2 but styled as h1 */}
                <div className="accordion" id="chargesAccordion">
                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingTollgates"> {/* Consider using h3 for accordion headers */}
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTollgates" aria-expanded="false" aria-controls="collapseTollgates">
                                Tollgates and Park Fees
                            </button>
                        </h3>
                        <div id="collapseTollgates" className="accordion-collapse collapse" aria-labelledby="headingTollgates" data-bs-parent="#chargesAccordion">
                            <div className="accordion-body">
                                All tollgate fees and park entry charges are to be borne by the customer. These fees are not included in the travel charges and must be paid directly at the tolls or park entries.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingDriverBatta"> {/* Consider using h3 for accordion headers */}
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDriverBatta" aria-expanded="false" aria-controls="collapseDriverBatta">
                                Driver Batta (Allowance)
                            </button>
                        </h3>
                        <div id="collapseDriverBatta" className="accordion-collapse collapse" aria-labelledby="headingDriverBatta" data-bs-parent="#chargesAccordion">
                            <div className="accordion-body">
                                A daily driver batta applies for trips that exceed a certain number of hours or include overnight stays. This allowance covers the driver's food and accommodation expenses.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingOtherCharges"> {/* Consider using h3 for accordion headers */}
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOtherCharges" aria-expanded="false" aria-controls="collapseOtherCharges">
                                Other Charges
                            </button>
                        </h3>
                        <div id="collapseOtherCharges" className="accordion-collapse collapse" aria-labelledby="headingOtherCharges" data-bs-parent="#chargesAccordion">
                            <div className="accordion-body">
                                Additional charges may apply for specific requirements or services not covered in the standard travel package, such as special equipment, additional stops, or service extensions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );


};

export default SRPrivacyPolicy;
