import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForCsv = () => {
    const imagePaths = {
        outstation: require("../assets/img/hv_img_2.png"),
        airportService: require("../assets/img/hv_img_3.png"),
        localService: require("../assets/img/csv_img_2.png")
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Our Cab Services Offerings in Vijayawada</h2>
            <div className="container">
                {/* Outstation Services Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.outstation}
                            alt="cab services in vijayawada: outstation"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Vijayawada Outstation Services</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>SR Car Travels presents a wide range of taxi options in Vijayawada to fulfill your <a href='https://srcartravels.in/outstation'>outstation travel</a> requirements, including both one-way drops and round trips. Given Vijayawada's strategic location, there is a multitude of captivating destinations to discover, such as Amaravati, Srisailam, Nagarjunakonda, Rajahmundry, and Bhavani Island.</p>
                        <p className='fs-6'>We offer highly cost-effective outstation cabs in Vijayawada, starting at just Rs. 9/Km. Additionally, we facilitate convenient one-way drops from Vijayawada to cities like Guntur, Tirupati, and more.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                {/* Local Cab Services Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.localService}
                            alt="Local Cab Services in Vijayawada"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Local Cab Services in Vijayawada</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Explore the wonders of Vijayawada with its fascinating attractions, promising an enriching local sightseeing odyssey. For your utmost comfort and convenience, SR Travels <a href='https://srcartravels.in/sp-offers'>offers local cab services</a> in Vijayawada. Our range of packages for local hourly bookings includes flexible options such as 4 hours/40 km for half-day cabs, 8 hours/80 km, or 12 hours/120 km for full-day cabs. SR Travels stands as your premier choice for discovering high-quality and cost-effective cabs in Vijayawada.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Local Cab Services Section */}

                {/* Airport Services Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.airportService}
                            alt="Airport Cab Services in Vijayawada"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Airport Services</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>When traveling to or from Vijayawada International Airport, situated approximately 20 km from the city’s main point, you may encounter challenges such as extended queues and time constraints. To escape these issues, consider booking an airport taxi in Vijayawada with SR Travels. Our services offer seamless airport transfers in Vijayawada, guaranteeing a comfortable and hassle-free journey to and from the airport.</p>
                    </div>
                </div>
            </div>
            <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}
        </>
    );
};

export default SRTravelOptionsForCsv;
