import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddDriverForm = () => {
    const navigate = useNavigate();
    const [driver, setDriver] = useState({
        name: '',
        salary: '',
        availableToRide: false,
        balance: '',
        number: '',
        advance: ''
    });
    const [successMessage, setSuccessMessage] = useState(''); // State to hold the success message

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDriver(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value.slice(0, name === 'name' ? 6 : value.length) // Limit characters for name field
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://srcartravels.in/addDriver', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(driver),
            });
            if (response.ok) {
                setSuccessMessage('Driver added successfully'); // Set success message
                // Optionally reset form here to initial state
                setDriver({
                    name: '',
                    salary: '',
                    availableToRide: false,
                    balance: '',
                    number: '',
                    advance: ''
                });

                setTimeout(() => {
                    navigate('/role_owner'); // Adjust this path as needed
                }, 5000);

            } else {
                setSuccessMessage('Failed to add driver'); // Set error message
            }
        } catch (error) {
            console.error('Error adding driver:', error);
            setSuccessMessage('Error adding driver'); // Set error message
        }
    };

    return (
        <>
            <div className="container mt-4">
                <h2 className="mb-3 text-center">
                    <i className="bi bi-person-plus-fill"></i> Add New Driver
                </h2>
                <form onSubmit={handleSubmit}>
                    {/* For each input, wrap with an input group to include icons */}
                    <div className="mb-3 input-group">
                        <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                        <input type="text" className="form-control" id="name" name="name" value={driver.name} onChange={handleChange} placeholder="Driver's Name" required />
                    </div>
                    {/* Available to Ride Checkbox - Consider leaving as is, since input groups are typically used with text inputs, not checkboxes */}
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="availableToRide" name="availableToRide" checked={driver.availableToRide} onChange={handleChange} />
                        <label className="form-check-label" htmlFor="availableToRide">Available to Ride</label>
                    </div>

                    {/* Balance Field */}
                    <div className="mb-3 input-group">
                        <span className="input-group-text"><i className="bi bi-wallet-fill"></i></span>
                        <input type="number" className="form-control" id="balance" name="balance" value={driver.balance} onChange={handleChange} placeholder="Balance" required />
                    </div>

                    {/* Contact Number Field */}
                    <div className="mb-3 input-group">
                        <span className="input-group-text"><i className="bi bi-telephone-fill"></i></span>
                        <input type="text" pattern="[6789][0-9]{9}" autoComplete="off" className="form-control" id="number" name="number" value={driver.number} onChange={handleChange} placeholder="Contact Number" required />
                    </div>

                    {/* Advance Field */}
                    <div className="mb-3 input-group">
                        <span className="input-group-text"><i className="bi bi-cash-stack"></i></span>
                        <input type="number" className="form-control" id="advance" name="advance" value={driver.advance} onChange={handleChange} placeholder="Advance" required />
                    </div>


                    {/* Example for Salary field with a different icon */}
                    <div className="mb-3 input-group">
                        <span className="input-group-text"><i className="bi bi-currency-dollar"></i></span>
                        <input type="number" className="form-control" id="salary" name="salary" value={driver.salary} onChange={handleChange} placeholder="Salary" required />
                    </div>
                    {/* Submit Button - Centered */}
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary"><i className="bi bi-plus-lg"></i> Add Driver</button>
                    </div>
                </form>

                {/* Success/Error message */}
                {successMessage && (
                    <div className="text-center mt-3 text-success">
                        {successMessage}
                    </div>
                )}


            </div>
            <hr></hr>
            <br />
        </>

    );
};

export default AddDriverForm;
