import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: for blur effect

const SRTravelOptions = () => {
    const imagePaths = {
        oneWayDrop: require("../assets/img/hv_img_2.png"),
        airportService: require("../assets/img/hv_img_3.png")
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">SR Car Travels’ Hyderabad to Vijayawada Car Rental Options</h2>
            <div className="container">

                {/* Outstation Services Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.oneWayDrop}
                            alt="Hyd to Vijayawada Cabs: One-Way Drop"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Hyd to Vijayawada Cabs: One-Way Drop</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Experience the ultimate convenience with SR Car Travels’ unbeatable one-way cab package from Hyderabad to Vijayawada. Our commitment to affordability ensures that the rates are unmatched for our valued customers. Simply book an online cab from Hyderabad to Vijayawada with the SR Car Travels website to enjoy this exclusive offer.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.airportService}
                            alt="Hyderabad Airport to Vijayawada Cab Services"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Hyderabad Airport to Vijayawada Cab Services</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>SR Car Travel takes pride in offering high-quality, on-time, and dependable cab services from Hyderabad Airport to Vijayawada. For an efficient and enjoyable journey, SR Travel is the ideal choice for booking a <a href='https://srcartravels.in/cabs-from-vijayawada-to-hyderabad'>cab from Hyderabad to Vijayawada.</a> Whether for local sightseeing or a trip, our team provides the best platform for reserving Hyderabad to Vijayawada cabs. Our options include various car options, such as sedans, Innovas, and SUVs, catering to your specific needs.</p>
                    </div>
                </div>
            </div>
            <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}
        </>
    );
};

export default SRTravelOptions;
