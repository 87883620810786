import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForTFVTH = () => {
    const imagePaths = {
        tfvh_2: require("../assets/img/tfvh-2.png"),
        tfvh_3: require("../assets/img/tfvh-3.png"),
        tfvh_4: require("../assets/img/tfvh-4.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Key Highlights of SR Car Travels</h2>
            <div className="container">
                
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tfvh_2}
                            alt="long drive cars in vijayawada: pdc_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Luxury & Comfort at Ease</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Experience luxurious and comfortable journeys with our premium fleet of cars. SR Car Travels elevates every trip into a lavish experience, guaranteeing that your travel is not just a mere journey but an unforgettable adventure.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tfvh_3}
                            alt="long drive cars in vijayawada: pdc_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Trusted Reputation</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Renowned for excellence, SR Car Travels is a beacon of quality in Vijayawada’s transport services. When you choose us, you partner with a trusted name committed to delivering exceptional travel experiences.</p>
                        
                    </div>
                </div>
                <hr style={thinLineStyle} />


                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.tfvh_4}
                            alt="long drive cars in vijayawada: pdc_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Transparent Pricing</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Our pricing strategy is built on clarity and honesty, ensuring you receive reasonable and fair quotes immediately. At SR Car Travels, we pride ourselves in fostering trust through transparent pricing without hidden charges.</p>
                        
                    </div>
                </div>
                <hr style={thinLineStyle} />
                
            </div>
         
        </>
    );
};

export default SRTravelOptionsForTFVTH;
