import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForVTS = () => {
    const imagePaths = {
        vts_2: require("../assets/img/vts-2.png"),
        vts_3: require("../assets/img/vts-3.png"),
        vts_4: require("../assets/img/vts-4.png"),
        vts_5: require("../assets/img/vts-5.png"),
        vts_6: require("../assets/img/vts-6.png"),
        vts_7: require("../assets/img/vts-7.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">More Than Just a Taxi: Experience the Difference with Seamless Travel in Vijayawada</h2>
            <div className="container">

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.vts_2}
                            alt="long drive cars in vijayawada: vts_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>No Car Ownership Hassle</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Escape the financial burdens and obligations associated with owning and upkeeping a car, such as insurance, parking fees, and maintenance costs. This will free up more resources for indulging in your passion for <a href="https://srcartravels.in/outstation" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>exploring new places</a>. </p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.vts_3}
                            alt="long drive cars in vijayawada: vts_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Pick Up & Drop Service</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Experience convenience with our seamless Vijayawada taxi services, ensuring you arrive at your desired destinations hassle-free. Eliminate the challenges of public transportation and parking as you travel to <a href="https://srcartravels.in/vijayawada-city-tour" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>enjoy the rich heritage of Vijayawada</a> of Vijayawada.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />


                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.vts_4}
                            alt="long drive cars in vijayawada: vts_4"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>One-Way & Round-Trip Options</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>At SR Car Travels, we provide convenient taxi services with <a href="https://srcartravels.in/login" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>flexible booking options</a> to accommodate your travel plans. Whether you're embarking on a quick journey or a multi-day excursion, our one-way and round-trip choices cater to your preferences. Opt for our one-way trip for seamless point-to-point transfer, or select the round-trip option for enhanced flexibility and peace of mind.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.vts_5}
                            alt="long drive cars in vijayawada: vts_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Transparent Pricing Structure</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>We uphold a commitment to transparency and clarity in our taxi pricing. Our simple and <a href="https://srcartravels.in/sp-offers" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>competitive pricing model offers</a> complete visibility without any hidden fees or surprises. Prioritizing honesty and integrity, we clearly outline our fare structure, empowering you with the knowledge of what to expect before booking your ride.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.vts_6}
                            alt="long drive cars in vijayawada: vts_6"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Experienced Drivers</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>With our extensive industry experience, our drivers consistently prioritize safety, punctuality, and customer satisfaction. Whether you're traveling for business or leisure, rest assured that our skilled drivers will expertly guide you to your destination with efficiency and professionalism. Enjoy a seamless and comfortable journey with SR Car Travels, where our skilled drivers are dedicated to surpassing your expectations on every trip.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.vts_7}
                            alt="long drive cars in vijayawada: vts_7"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Flexibility for Last-Minute Travel</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>With us, you can effortlessly arrange for a taxi in Vijayawada, even if your travel plans are unplanned. This ensures that you can secure transportation without prior arrangements, accommodating spontaneous journeys and impromptu travel adventures.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

            </div>

        </>
    );
};

export default SRTravelOptionsForVTS;
