import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CabServiceHighlights = () => {
    const imagePath = require("../assets/img/hv_img_4.png");

    return (
        <div className="container py-5">
            <div className="row align-items-center">
                <div className="col-lg-4 mb-4 mb-lg-0">
                    <LazyLoadImage
                        src={imagePath}
                        alt="Key Highlights of Our Cab Services in Hyderabad"
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ height: '280px', width: '380px' }}
                    />
                </div>
                <div className="col-lg-8">
                    <h2 className="mb-4 fs-5">Key Highlights of Our Cab Services in Hyderabad</h2>
                    <ul className="list-unstyled fs-6">
                        <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>Fair and Affordable Prices</li>
                        <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>Verified and Professional Drivers</li>
                        <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>On-time Pick-up & Drop Services</li>
                        <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>Consistent Customer Support</li>
                    </ul>
                    <a href="https://srcartravels.in/car-rentals-vijayawada" className="btn btn-lg fs-6" style={{ backgroundColor: "#113042", color: "#ffffff" }}>Know More</a>
                </div>
            </div>
        </div>
    );
};

export default CabServiceHighlights;
