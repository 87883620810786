import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentHydToVij = () => {
    const faqs = [
        {
            question: "What are the times your cabs operate from Hyderabad to Vijayawada?",
            answer: "SR Car Travels offers round-the-clock drop cab services from Hyderabad to Vijayawada, ensuring that customers have convenient and reliable transportation whenever they need it. With our 24/7 assistance, booking a cab with us provides peace of mind and flexibility, serving the diverse needs of our clients."
        },
        {
            question: "What are the different cab options available from Hyderabad to Vijayawada?",
            answer: "Multiple ride options are at your disposal for traveling from Hyderabad to Vijayawada. For groups of up to 4 people, you can choose either Dzire or Sedan. If up to 6 people accompany you, Innova is the ideal ride option for your journey."
        },
        {
            question: "How much time does it take to reach Vijayawada from Hyderabad?",
            answer: "On average, the travel time from Hyderabad to Vijayawada is approximately 6 hours. However, it's important to note that the duration may vary due to the traffic in the city."
        },
        {
            question: "Do I need to provide my ID proof for cab booking?",
            answer: "To book a taxi in Hyderabad, there is no need to provide any ID proof. You can conveniently book a taxi directly from our website without any hassle."
        },
        {
            question: "I want to book a return trip to Hyderabad. What do I have to do about it?",
            answer: "You can conveniently book your return trip on our website by providing your journey details and completing the booking process."
        }
    ];

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                                "@type": "Question",
                                "name": "What are the times your cabs operate from Hyderabad to Vijayawada?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "SR Car Travels offers round-the-clock drop cab services from Hyderabad to Vijayawada, ensuring that customers have convenient and reliable transportation whenever they need it. With our 24/7 assistance, booking a cab with us provides peace of mind and flexibility, serving the diverse needs of our clients."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "What are the different cab options available from Hyderabad to Vijayawada?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Multiple ride options are at your disposal for traveling from Hyderabad to Vijayawada. For groups of up to 4 people, you can choose either Dzire or Sedan. If up to 6 people accompany you, Innova is the ideal ride option for your journey."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "How much time does it take to reach Vijayawada from Hyderabad?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "On average, the travel time from Hyderabad to Vijayawada is approximately 6 hours. However, it's important to note that the duration may vary due to the traffic in the city."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "Do I need to provide my ID proof for cab booking?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "To book a taxi in Hyderabad, there is no need to provide any ID proof. You can conveniently book a taxi directly from our website without any hassle."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "I want to book a return trip to Hyderabad. What do I have to do about it?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "You can conveniently book your return trip on our website by providing your journey details and completing the booking process."
                                }
                            }
                        ]
                    })}
                </script>
            </Helmet>

            <div className="container py-4">
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body className="fw-bold">{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentHydToVij;
