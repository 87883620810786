import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const SRPancharamaKshetras = () => {
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };
  const locations = [
    {
      id: 'amaramam',
      title: 'Amararama in Amaravati',
      description: 'Amararama is a Hindu temple located in the city of Amaravati, in the Indian state of Andhra Pradesh. The temple is dedicated to Lord Vishnu and is considered one of the most important pilgrimage sites for devotees of Vaishnavism, a major sect of Hinduism. The temple is known for its rich history and cultural significance, and is considered one of the most sacred places in South India.',
      images: ['/assets/img/amaramam/slide-1.png', '/assets/img/amaramam/slide-2.png', '/assets/img/amaramam/slide-3.png'],
      bookNowLink: 'tel:8886556632',
    },
    {
      id: 'draksharama',
      title: 'Bhimeshwara in Draksharamam',
      description: 'Bhimeswara Temple is a Hindu temple located in Draksharamam, in the East Godavari district of Andhra Pradesh, India. The temple is dedicated to Lord Shiva and is considered one of the five famous "Pancharama" temples in the state of Andhra Pradesh. These five temples are believed to have been built by the King Kumara Bhima of the Eastern Chalukya dynasty in the 10th century AD. The Bhimeshwara Temple is known for its architectural and historical significance, and is considered a major pilgrimage site for devotees of Lord Shiva. The temple complex features several shrines, a large tank, and several beautifully carved pillars and sculptures.',
      images: ['/assets/img/draksharama/slide-1.png', '/assets/img/draksharama/slide-2.png', '/assets/img/draksharama/slide-3.png'],
      bookNowLink: 'tel:8886556632',
    },
    {
      id: 'somarama',
      title: 'Somarama in Bhimavaram',
      description: 'Somarama Temple is a Hindu temple located in Bhimavaram, in the West Godavari district of Andhra Pradesh, India. The temple is dedicated to Lord Someshwara, an incarnation of Lord Shiva, and is considered one of the five famous "Pancharama" temples in the state of Andhra Pradesh. These five temples are believed to have been built by the King Kumara Bhima of the Eastern Chalukya dynasty in the 10th century AD. The Somarama Temple is known for its historical and architectural significance, and is considered a major pilgrimage site for devotees of Lord Shiva. The temple complex features several shrines, a large tank, and several beautifully carved pillars and sculptures.',
      images: ['/assets/img/somarama/slide-1.png', '/assets/img/somarama/slide-2.png', '/assets/img/somarama/slide-3.png'],
      bookNowLink: 'tel:8886556632',
    },
    {
      id: 'ksheerarama',
      title: 'Ksheerarama in Palakollu',
      description: 'Ksheerarama Temple is a Hindu temple located in Palakollu, in the West Godavari district of Andhra Pradesh, India. The temple is dedicated to Lord Shiva and is considered one of the five famous "Pancharama" temples in the state of Andhra Pradesh. These five temples are believed to have been built by the King Kumara Bhima of the Eastern Chalukya dynasty in the 10th century AD. The Ksheerarama Temple is known for its historical and architectural significance, and is considered a major pilgrimage site for devotees of Lord Shiva. The temple complex features several shrines, a large tank, and several beautifully carved pillars and sculptures.',
      images: ['/assets/img/ksheerarama/slide-1.png', '/assets/img/ksheerarama/slide-2.png', '/assets/img/ksheerarama/slide-3.png'],
      bookNowLink: 'tel:8886556632',
    },
    {
      id: 'kumararama',
      title: 'Kumararama in Samalkot',
      description: 'Kumararama Temple is a Hindu temple located in Samalkot, in the East Godavari district of Andhra Pradesh, India. The temple is dedicated to Lord Kumararama, an incarnation of Lord Vishnu, and is considered one of the five famous "Pancharama" temples in the state of Andhra Pradesh. These five temples are believed to have been built by the King Kumara Bhima of the Eastern Chalukya dynasty in the 10th century AD. The Kumararama Temple is known for its historical and architectural significance, and is considered a major pilgrimage site for devotees of Lord Vishnu. The temple complex features several shrines, a large tank, and several beautifully carved pillars and sculptures.',
      images: ['/assets/img/kumararama/slide-1.png', '/assets/img/kumararama/slide-2.png', '/assets/img/kumararama/slide-3.png'],
      bookNowLink: 'tel:8886556632',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pancharama Kshetras | SR Car Travels</title>
        <meta name="description" content="SR Car Travels is best Online cabs booking services in Pancharama Kshetras, Car Rentals for Vijayawada, Hyderabad. We have a wide range of cars available at all times." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://srcartravels.in/pancharama-kshetras" />

        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta property="og:title" content="Pancharama Kshetras | SR Car Travels" />
        <meta property="og:description" content="SR Car Travels is best Online cabs booking services in Pancharama Kshetras, Car Rentals for Vijayawada, Hyderabad. We have a wide range of cars available at all times." />
      </Helmet>
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12 text-center mb-4">
            <h1>Pancharama Kshetras</h1>
            <p><i><strong>Advisable to book your travel arrangements in advance to ensure that you have a smooth and comfortable journey. <a href="https://srcartravels.in/" style={{ color: 'inherit', textDecoration: 'underline' }}>Sr Car Travels</a> always tries to provide good facilities so that travelers can enjoy a stress-free and memorable trip without worrying about anything.</strong></i></p>
            {!readMore && <span id="dots">...</span>}
            {readMore && (
              <div id="more">
                <p><i><strong>On account of the destruction of Tarakasura, Lord Muruga had to destroy the Sivalinga that protected him. The breaking of Sivalinga resulted in the formation of 5 temples.</strong></i></p>
                {/* Additional story content */}
                <p><i><strong>According to legend, these five pieces were installed as Siva Lingams at five temples by Indra, Surya, Chandra, Vishnu, and Kumara Swamy at the respective places.</strong></i></p>
                <p><i><strong>Although these temples can be visited on any day, there is a belief that visiting all the five temples on a single day in the holy month of Kartik is very rewarding. Tourists and pilgrims can avail the services provided by SR Car Travels during the month to help visitors pay their obeisance at all the temples in a day.</strong></i></p>
              </div>
            )}
            <button className="btn btn-success text-center" onClick={toggleReadMore}>
              {readMore ? 'Read less' : 'Read more'}
            </button>
          </div>
        </div>
        <hr className="rounded" />
        <div className="row">
          <div className="row">
            {locations.map((location, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div className="card">
                  <div id={`${location.id}Carousel`} className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      {location.images.map((_, idx) => (
                        <button key={idx} type="button" data-bs-target={`#${location.id}Carousel`} data-bs-slide-to={idx} className={idx === 0 ? 'active' : ''} aria-label={`Slide ${idx + 1}`}></button>
                      ))}
                    </div>
                    <div className="carousel-inner">
                      {location.images.map((img, idx) => (
                        <div key={idx} className={`carousel-item ${idx === 0 ? 'active' : ''}`}>
                          <img src={img} className="d-block w-100" alt={`Slide ${idx + 1}`} />
                        </div>
                      ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target={`#${location.id}Carousel`} data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target={`#${location.id}Carousel`} data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title text-center">{location.title}</h4>
                    <p className="card-text">{location.description}</p>
                    <a href={location.bookNowLink} className="btn btn-primary">Book Now</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SRPancharamaKshetras;
