import React from 'react';
import './SRContact.css';
const SRContact = () => {
  return (
    <div className="sr-contact-container">
      <h2 className="sr-contact-title">Get in Touch</h2>
      <div className="sr-contact-info">
        <p className="sr-contact-item">
          <strong>Phone:</strong> <a href="tel:+91-8886556632">+91-8886556632</a>
        </p>
        <p className="sr-contact-item">
          <strong>Email:</strong> <a href="mailto:contact@srcartravels.in">contact@srcartravels.in</a>
        </p>
      </div>
    </div>
  );
};

export default SRContact;
