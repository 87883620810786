import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForCsv = () => {
    const faqs = [
        {
            question: "Do you also provide 24x7 support to all your customers?",
            answer: "Our dedication to round-the-clock availability ensures that customers can access assistance anytime, providing the support they need, day or night."
        },
        {
            question: "We are a family of 7. Can we get a big cab for the Vijayawada city tour?",
            answer: "At SR Car Travels, we offer a diverse range of cab options, spanning from compact cars to luxurious vehicles, to cater to various preferences and requirements. Our selection allows you to choose the car that best suits your needs and preferences."
        },
        {
            question: "Can I book a cab without any advance booking?",
            answer: "Yes, you can seamlessly book any cab service without advanced booking with our services. However, it is suggested that you should secure your cab booking by paying in advance."
        },
        {
            question: "Can we pay via UPI upon reaching the destination?",
            answer: "Upon reaching your destination, you can securely and conveniently make payments for your cab booking using various digital payment methods such as UPI, Netbanking, Debit Card, Credit Card, and more."
        },
        {
            question: "Does SR Travels provide outstation cab services from Vijayawada?",
            answer: "Yes, we do provide outstation cab services from Vijayawada city to places like Chennai, Hyderabad, Goa, etc."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForCsv;
