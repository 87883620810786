import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForCTIG = () => {
    const imagePaths = {
        ctig_2: require("../assets/img/CTIG-2.png"),
        ctig_3: require("../assets/img/CTIG-3.png"),
        ctig_4: require("../assets/img/CTIG-4.png"),
        ctig_5: require("../assets/img/CTIG-5.png"),
        ctig_6: require("../assets/img/CTIG-6.png"),
        ctig_7: require("../assets/img/CTIG-7.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">How We Make Your Travel Safe and Comfortable in Guntur?</h2>
            <div className="container">

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.ctig_2}
                            alt="car travels in guntur: ctig_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>High Level of Comfort</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>At SR Car Travels in Guntur, we place the highest priority on passenger comfort. Our carefully maintained fleet is designed to deliver an exceptionally comfortable journey. With spacious interiors, plush seating, and entertainment options in every vehicle, we ensure that every detail contributes to a luxurious and enjoyable travel experience. Whether you're traveling for work or leisure purposes – our cars are equipped to offer a seamless and relaxing ride.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.ctig_3}
                            alt="car travels in guntur: ctig_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Transparency in Prices</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'><a href="https://srcartravels.in/sp-offers" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>Our pricing structure</a> at SR Car Travels is transparent and free of hidden fees, giving you full visibility into the cost breakdown before confirming your journey. From hourly rentals to personalized travel packages, we provide competitive rates without sacrificing service quality. With our clear pricing policy, you can have peace of mind knowing that you're receiving exceptional value for your money when selecting SR Car Travels for your transportation requirements in Guntur. </p>

                    </div>
                </div>
                <hr style={thinLineStyle} />


                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.civ_4}
                            alt="car travels in guntur: ctig_4"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Extensive Coverage of the City</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>From vibrant commercial districts to peaceful residential neighborhoods, our diverse range of vehicles is always at your service for all your transportation needs throughout the city. Whether you're commuting to work, sightseeing, attending gatherings, or handling daily tasks, count on us for hassle-free and efficient travel. With our reliable drivers' punctuality and unwavering commitment to customer satisfaction, SR Car Travels stands as your dependable partner in navigating Guntur with comfort and ease. </p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.ctig_5}
                            alt="car travels in guntur: ctig_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Flexible Options for Travelling</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>With our diverse fleet of vehicles and flexible booking options, you have the freedom to select the ideal car for your specific needs and journey duration. Our dedicated drivers are committed to ensuring your safety and comfort, allowing you to unwind and relish the journey. Embrace hassle-free travel with SR Car Travels - where each ride is an invitation to discover new destinations and forge unforgettable experiences.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.ctig_6}
                            alt="car travels in guntur: ctig_6"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Highly Experienced Drivers</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>With years of expertise in navigating the roads, our drivers leverage extensive knowledge of local routes and traffic patterns to ensure efficient and timely travel to your destination. They go beyond being just chauffeurs; they are professional, courteous, and dedicated to ensuring your safety and comfort throughout the journey. At SR Car Travels in Guntur, we prioritize your satisfaction and well-being by putting you in the hands of skilled professionals who deliver a seamless and enjoyable journey every time while prioritizing professionalism at all times.</p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.ctig_7}
                            alt="car travels in guntur: ctig_7"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Doorstep Pickup and Drop Services</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Need to catch a flight? Head to a meeting? Our skilled drivers will arrive promptly at your door for a comfortable and stylish ride. Once you reach your destination, we'll ensure a hassle-free drop-off right where you need to be. With SR Car Travels, you can say goodbye to scrambling for cabs or navigating unfamiliar streets. Focus on enjoying your trip – we'll handle the logistics.  Experience the ease and reliability of doorstep services – every journey begins and ends at your door for maximum comfort and peace of mind. </p>

                    </div>
                </div>
                <hr style={thinLineStyle} />

            </div>

        </>
    );
};

export default SRTravelOptionsForCTIG;
