import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import SRTravelOptionsForCsv from './SRTravelOptionsForCsv';
import CabServiceFollowSteps from './CabServiceFollowSteps';
import TaxiTypesAndCapacity from './TaxiTypesAndCapacity';
import RentACarCsv from './RentACarCsv';
import FAQsComponentForCsv from './FAQsComponentForCsv';
import UniqueFeatures from './UniqueFeatures';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/csv_img_1.png"),
    require("../assets/img/g_frame_2.png"),
    require("../assets/img/g_frame_3.png")
];

const CabServicesInVijayawada = () => {
    return (
        <>
            <Helmet>
                <title>Cab Services in Vijayawada | SR Car Travels</title>
                <meta name="description" content="SR Car Travels is your go-to solution for reliable and budget-friendly intercity cab services in Vijayawada. For more details, contact us at +91-8886556632." />
                <link rel="canonical" href="https://srcartravels.in/cab-services-in-vijayawada" />
                <meta name="robots" content="index, follow" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Cab Services in Vijayawada | SR Car Travels" />
                <meta property="og:description" content="SR Car Travels is your go-to solution for reliable and budget-friendly intercity cab services in Vijayawada. For more details, contact us at +91-8886556632." />

            </Helmet>
            <SRCustomForm />
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8">
                        <h1 className="fs-1 fw-bold mb-3">Get Budget-Friendly Travel with SR Travels Cab Services in Vijayawada</h1>
                        <p className="fs-6">If you are looking for reliable and budget-friendly cab rental services for intercity travel in Vijayawada, then you've come to the right place. As you plan your next trip to your favorite destination, we at <a href='https://srcartravels.in/'>SR Car Travels</a> are here to provide top-quality and easy-to-book intercity cab services.</p>
                        <p className="fs-6">Wherever your journey takes you, our cab service ensures a budget-friendly and reliable travel journey. Experience our car services in Vijayawada with your loved ones and take advantage of exclusive discounts. Choose our cabs for a full day to explore your preferred destinations. Our <a href='https://srcartravels.in/hyderabad-to-vijayawada-cab-booking'>premium cab service</a> allows you to access all major attractions and even reserve taxis for Vijayawada airport and railway station transfers. </p>
                        <p className="fs-6">Booking our city cab services is just a click away, ensuring easy and convenient travel throughout the city.</p>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-end">
                        <LazyLoadImage
                            src={imagePaths[0]}
                            alt="cab services in vijayawada"
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ width: "350px", height: "380px", borderRadius: "20px" }}
                        />
                    </div>
                </div>
            </div>
            <SRTravelOptionsForCsv />
            <CabServiceFollowSteps />
            <TaxiTypesAndCapacity />
            <UniqueFeatures />
            <RentACarCsv />
            <FAQsComponentForCsv />
        </>
    );
};

export default CabServicesInVijayawada;
