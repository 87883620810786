import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BookingOptionsForCRIV = () => {

    const imagePaths = {
        bookingImg: require("../assets/img/CRIV-8.png"),
        phoneImg: require("../assets/img/CRIV-9.png"),
        emailImg: require("../assets/img/CRIV-10.png")
    };

    const bookingOptions = [
        {
            imgSrc: imagePaths.bookingImg,
            title: "Local Car Rentals",
            text: (<>
                For top-notch local car rentals in Vijayawada, look no further than SR Car Rentals. Our extensive fleet of well-maintained vehicles and unwavering dedication to customer satisfaction make us the leading choice. We provide a seamless and dependable transportation solution for all your local travel requirements. Discover the convenience and versatility of local car rentals with us as your reliable companion for stress-free travel in Vijayawada. 
            </>),
        },
        {
            imgSrc: imagePaths.phoneImg,
            title: "Outstation Car Rentals",
            text: (
                <span>
                    If you're looking to venture beyond Vijayawada and explore beautiful destinations, consider booking our chauffeur-driven cars for one-way or round-trip travel. Our experienced drivers will take care of the driving and navigation, allowing you to unwind and fully savor the journey. Opting for SR Car rental in Vijayawada for your onward travels can provide a secure and convenient experience.
                </span>
            ),
        },
        {
            imgSrc: imagePaths.emailImg,
            title: "Corporate Travel Solutions",
            text: (
                <span>
                    Streamline your corporate travel with our comprehensive range of business travel solutions. Our services encompass executive car rentals and chauffeur-driven transportation, ensuring that we cater to the distinct requirements of corporate clients with unwavering professionalism and exceptional efficiency. Book your hassle-free business travel from SR Car rentals Vijayawada.  
                </span>
            )
        }
    ];

    return (
        <div className="container py-4">
            <h2 className="mb-4 text-center fs-4">Explore Our Car Rentals Services in Vijayawada</h2>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {bookingOptions.map((option, index) => (
                    <div key={index} className="col">
                        <div className="card h-100">
                            <LazyLoadImage
                                src={option.imgSrc} // Now using the correct image for each option
                                alt={option.title}
                                effect="blur"
                                className="card-img-top"
                            />
                            <div className="card-body text-center">
                                <h3 className="card-title fs-5">{option.title}</h3>
                                <p className="card-text fs-6">{option.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BookingOptionsForCRIV;
