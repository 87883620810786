import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SRLoginComponent = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Reset error message on new login attempt
        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const response = await axios.post('https://srcartravels.in/login', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.role) {
                // Assuming role-based navigation requirement
                const { role } = response.data;
                sessionStorage.setItem('userData', JSON.stringify(response.data)); // Store user data in session
                sessionStorage.setItem('user', JSON.stringify(response.data.user));
                navigate(`/${role.toLowerCase()}`); // Navigate based on the role
            } else {
                setErrorMessage('Login failed. Please check your credentials.');
            }
        } catch (error) {
            setErrorMessage('Login failed. Please check your credentials.');
            console.error('Login failed:', error.response);
        }
    };

    return (
        <>
            <Helmet>
                <title>Login | Create a New Account | SR Car Travels</title>
                <meta name="description" content="Register and Signin to your SR Car Travels account and start booking car rentals for SUV, Sedan, Innova models. Explore our top-quality vehicles and book your ride today." />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/login" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Login | Create a New Account | SR Car Travels" />
                <meta property="og:description" content="Register and Signin to your SR Car Travels account and start booking car rentals for SUV, Sedan, Innova models. Explore our top-quality vehicles and book your ride today." />
            </Helmet>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h1 className="h1-small text-center mb-4">Login to Your Account</h1>
                                <form onSubmit={handleLogin}>
                                    <div className="mb-3">
                                        <div className="input-group">
                                            <span className="input-group-text"><i className="fa fa-user"></i></span>
                                            <input type="text" id="username" className="form-control" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="input-group">
                                            <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                            <input type="password" id="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                        </div>
                                    </div>
                                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                                    <button type="submit" className="btn btn-primary w-100">Log In</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SRLoginComponent;
