import React from 'react';
import { Link } from 'react-router-dom';
import './SRFooter.css'; // Make sure to update this file with new styles as shown below
import SRAddress from './SRAddress';
import SRContact from './SRContact';

const SRFooter = () => {
  return (
    <footer className="mt-5" style={{ backgroundColor: '#173141', color: '#ffffff' }}>
      <div className="footer-content">
        <SRAddress />
        <SRContact />
      </div>

      <div className="container text-white">
        <div className="footer-title text-center">
          SR Car Travels - Your Trusted Partner for Car Rentals in Vijayawada
        </div>
        <div className="container mt-5">
          <div className="row text-center mt-4 gx-4 gy-3">
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <Link to="/best-car-travels-in-vijayawada" className="footer-link d-block text-decoration-none">
                <i className="bi bi-star-fill me-2"></i>Best Car Travels in Vijayawada
              </Link>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <Link to="/car-rentals-vijayawada" className="footer-link d-block text-decoration-none">
                <i className="bi bi-briefcase-fill me-2"></i>Car Rentals Vijayawada
              </Link>
            </div>
            {/* For external links, using <a> with rel="noopener noreferrer" for security */}
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/car-travels-vijayawada" className="footer-link d-block text-decoration-none" rel="noopener noreferrer">
                <i className="bi bi-car-front-fill me-2"></i>Car Travels in Vijayawada
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <Link to="/cabs-from-vijayawada-to-hyderabad" className="footer-link d-block text-decoration-none">
                <i className="bi bi-geo-alt-fill me-2"></i>Cabs Vijayawada to Hyderabad
              </Link>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <Link to="/hyderabad-to-vijayawada-cab-booking" className="footer-link d-block text-decoration-none">
                <i className="bi bi-calendar-check-fill me-2"></i>Hyderabad to Vijayawada Cab
              </Link>
            </div>
            {/* Correcting this section to follow the standard */}
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/car-travels-vijayawada-ap" className="footer-link d-block text-decoration-none">
                <i className="bi bi-key-fill me-2"></i>Car Travels in Vijayawada AP
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/car-rentals-vijayawada-ap" className="footer-link d-block text-decoration-none">
                <i className="bi bi-briefcase-fill me-2"></i>Car Rentals in Vijayawada AP
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/car-travellers-near-me" className="footer-link d-block text-decoration-none">
                <i className="bi bi-people-fill me-2"></i>Find Car Travellers Near Me
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/vijayawada-to-hyderabad-cabs" className="footer-link d-block text-decoration-none">
                <i className="bi bi-geo-alt-fill me-2"></i>Vijayawada to Hyderabad Cabs
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/taxi-from-hyderabad-to-vijayawada" className="footer-link d-block text-decoration-none">
                <i className="bi bi-calendar-check-fill me-2"></i>Taxi from Hyderabad to Vijayawada
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb-3">
              <a href="/cars-for-rent-vijayawada" className="footer-link d-block text-decoration-none">
                <i className="bi bi-key-fill me-2"></i>Cars for Rent in Vijayawada
              </a>
            </div>
          </div>
        </div>

        {/* Social Icons & Policy Links */}
        <div className="row mt-3 align-items-center">
          <div className="col-12 col-md-6 text-center text-md-start mb-3 mb-md-0">
            <a href="https://www.instagram.com/sr_car_travels/" target="_blank" rel="noopener noreferrer" className="me-4 text-decoration-none">
              <i className="bi bi-instagram fs-3" aria-label="Instagram"></i>
            </a>
            <a href="https://www.facebook.com/srcartravels/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
              <i className="bi bi-facebook fs-3" aria-label="Facebook"></i>
            </a>
          </div>

          <div className="col-12 col-md-6 text-center text-md-end">
            <Link to="/privacy-policy" className="btn btn-outline-secondary text-white">Privacy Policy</Link>
          </div>
        </div>

        <p className="text-center mt-3">&copy; 2023 SR Car Travels. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default SRFooter;