import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Custom404 = () => {
    return (
        <>
            <Helmet>
                <title>Page Not Found | SR Car Travels</title>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/custom-404" />
            </Helmet>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <h1 className="display-1 fw-bold text-danger"><i className="bi bi-exclamation-triangle-fill"></i> 404</h1>
                        <p className="fs-3"> <span className="text-primary"><i className="bi bi-emoji-frown"></i> Oops!</span> The page you're looking for doesn't exist.</p>
                        <p className="lead">
                            If you think this is an error, please
                            <a href="tel:+918886556632" className="text-decoration-none fw-bold"><i className="bi bi-telephone-fill"></i> call us directly at (+91) 8886556632</a> for immediate assistance.
                        </p>
                        <p className="mt-3">
                            You can also click on the buttons below to navigate back to the home page or explore our services.
                        </p>
                        <Link to="/" className="btn btn-primary btn-lg mt-4"><i className="bi bi-house-door-fill"></i> Go Home</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Custom404;
