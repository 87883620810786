import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Adjust these image paths according to your project structure
const imagePaths = {
    cityTour: require("../assets/img/hv_img_2.png"),
    luxuryCars: require("../assets/img/hv_img_3.png"),
    customerSatisfaction: require("../assets/img/csv_img_2.png")
};

const BestCarTravelsVijayawada = () => {
    // Custom style for the thin line
    const thinLineStyle = {
        width: '50%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Experience Premier Car Travels in Vijayawada with SR Travels</h2>
            <div className="container">
                {/* Discover Vijayawada Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.cityTour}
                            alt="Explore Vijayawada with SR Car Travels"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Discover Vijayawada: Local Sightseeing with Comfort</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Unlock the beauty of Vijayawada, a city rich with culture and history, with SR Car Travels. Our local sightseeing services ensure you explore every nook and cranny of Vijayawada comfortably and stylishly. Whether it's the majestic Kanaka Durga Temple or the serene Undavalli Caves, embark on an unforgettable journey with us.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} />

                {/* Seamless Airport Transfers Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.luxuryCars}
                            alt="Smooth Airport Transfers in Vijayawada"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Seamless Airport Transfers</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Bid farewell to the stress of airport commutes with our reliable airport transfer services. SR Car Travels ensures your journeys to and from Vijayawada Airport are punctual, comfortable, and hassle-free. Let us handle the logistics while you relax and prepare for the journey ahead.</p>
                    </div>
                </div>
                <hr style={thinLineStyle} />

                {/* Outstation Adventures Section */}
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.customerSatisfaction}
                            alt="Outstation Trips from Vijayawada"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Outstation Adventures Await</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Explore beyond Vijayawada with our outstation trip services. From weekend getaways to extended tours, SR Car Travels offers a fleet of vehicles to suit your travel needs, ensuring every journey is a new adventure. Experience the freedom of the open road with us.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BestCarTravelsVijayawada;
