import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Update the image paths according to your project's folder structure
const imagePaths = {
    luxuryAndComfort: require("../assets/img/csv_img_5.png"),
    extensiveCoverage: require("../assets/img/csv_img_6.png"),
    trustedReputation: require("../assets/img/csv_img_7.png"),
    transparentPricing: require("../assets/img/csv_img_8.png")
};

const featureDescriptions = {
    luxuryAndComfort: "Embark on journeys that blend luxury with comfort, courtesy of our premium fleet. SR Car Travels transforms every trip into a lavish affair, ensuring your travel in Vijayawada is not just a journey, but a memorable experience.",
    extensiveCoverage: (
        <>
            Navigate through Vijayawada or venture to distant locales with ease. Our wide-ranging services cover every corner of the city and beyond, offering seamless travel solutions no matter your destination.
        </>
    ),
    trustedReputation: "Renowned for excellence, SR Car Travels stands as a beacon of quality in Vijayawada's transport services. Choosing us means partnering with a trusted name committed to delivering exceptional travel experiences.",
    transparentPricing: "Clarity and honesty underpin our pricing strategy, ensuring you receive straightforward and fair quotes from the outset. SR Car Travels prides itself on fostering trust through transparent pricing, devoid of hidden charges."
};

const HomePageUniqueFeatures = () => {
    return (
        <div className="container py-5">
            <h2 className="text-center mb-4 fw-bold fs-3">What Makes Us Different From Other Cab Services in Vijayawada?</h2>
            {Object.entries(imagePaths).map(([key, imagePath], index) => (
                <div className="row align-items-center mb-5" key={key}> {/* Use row and align-items-center to align content */}
                    <div className={`col-md-4 ${index % 2 !== 0 ? 'order-md-2' : ''}`}> {/* Conditionally switch order for alternate rows */}
                        <LazyLoadImage
                            src={imagePath}
                            alt={key}
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    <div className={`col-md-8 ${index % 2 !== 0 ? 'order-md-1' : ''}`}> {/* Text takes the remaining space */}
                        <h3 className="fw-bold">{key.replace(/([A-Z])/g, ' $1').trim()}</h3>
                        {typeof featureDescriptions[key] === 'string' ? (
                            <p>{featureDescriptions[key]}</p>
                        ) : (
                            featureDescriptions[key]
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HomePageUniqueFeatures;
