import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SRContactForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mobNumber: '',
    name: 'test',
    email: 'customer@gmail.com', // Default or gathered from the form
    // Add other fields as necessary
  });

  // State for submission message
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const contactForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://srcartravels.in/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        const data = await response.text(); // Use .text() initially to avoid JSON parsing issues
        setIsSuccess(true);
        setMessage('Form submitted successfully.');
        navigate('/thank-you');

      } else {
        // The server responded with an error status
        const errorText = await response.text(); // Get the response text to display or log
        console.error('Server responded with an error:', errorText);
        setIsSuccess(false);
        setMessage('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setIsSuccess(false);
      setMessage('An error occurred. Please try again.');
    }
  };


  return (
    <div className="container" style={{ backgroundColor: '#173141', color: '#ffffff' }}>
      <h4 className="text-center text-white">Get In Touch! For Booking or More Details.</h4>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 pb-5">
          <form id="form" onSubmit={contactForm}>
            <div className="card border-primary rounded-0">
              <div className="card-header p-0">
                <div className="bg-info text-white text-center py-2">
                  <h3><i className="fas fa-envelope"></i> Contact Us</h3>
                  <p className="m-0">We will gladly help you</p>
                </div>
              </div>
              <div className="card-body p-3">
                {/* Name Field */}
                <div className="mb-3 text-center">
                  <div className="input-group">
                    <span className="input-group-text"><i className="fas fa-user text-info"></i></span>
                    <input type="text" className="form-control" placeholder="Name (Optional)" name="name" onChange={handleChange} />
                  </div>
                </div>
                {/* Phone Field */}
                <div className="mb-3 text-center">
                  <div className="input-group">
                    <span className="input-group-text"><i className="fas fa-phone text-info"></i></span>
                    <input type="text" className="form-control" pattern="[6789][0-9]{9}" placeholder="Phone Number *" name="mobNumber" required onChange={handleChange} />
                  </div>
                </div>
                {/* Email Field */}
                <div className="mb-3 text-center">
                  <div className="input-group">
                    <span className="input-group-text"><i className="fas fa-envelope text-info"></i></span>
                    <input type="email" className="form-control" placeholder="srcartravels@gmail.com (Optional)" name="email" onChange={handleChange} />
                  </div>
                </div>
                {/* Submit Button */}
                <div className="text-center">
                  <input type="submit" value="Submit" className="btn btn-info btn-block rounded-0 py-2" />
                </div>
              </div>
            </div>
          </form>
          {/* Conditional rendering for submission message */}
          <div className={`alert ${isSuccess ? 'alert-success' : 'alert-danger'} text-center`} role="alert" style={{ display: message ? 'block' : 'none' }}>
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SRContactForm;
