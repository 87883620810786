import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForCabsInVijayawada = () => {
    const faqs = [
        {
            question: "For how many hours is a driver on duty?",
            answer: "Typically, our drivers' duties vary according to your travel itinerary. For local driving services, our drivers adhere to an 8-hour work schedule, while for outstation services, the work time is 12 hours. This ensures that they are adequately rested and able to provide safe & reliable transportation throughout your journey."
        },
        {
            question: "What if I need to cancel my reservation for cabs in Vijayawada?",
            answer: "According to our policies and rules, you will receive a refund that aligns with your cancellation terms. Kindly note that refunds vary for each journey."
        },
        {
            question: "Does the price include Driver charges and Night charges?",
            answer: "The price includes driver charges and night charges. If you need further assistance or have any other requests, please contact the SR Car Travels team."
        },
        {
            question: "How can I contact SR Car Travels in case of an emergency during my journey?",
            answer: "Your emergency contact details are in your booking confirmation for easy access. Our dedicated 24/7 customer care team is readily available to provide any support or help you may require, ensuring that help is always just a call away."
        },
        {
            question: "Can I book a cab for my round-trip journey?",
            answer: "At SR Car Travels, we offer both one-way and round-trip journeys to cater to our customers' travel needs."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForCabsInVijayawada;
