import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BookingOptionsForCIV = () => {

    const imagePaths = {
        bookingImg: require("../assets/img/CIV-6.png"),
        phoneImg: require("../assets/img/CIV-7.png"),
        emailImg: require("../assets/img/CIV-8.png")
    };

    const bookingOptions = [
        {
            imgSrc: imagePaths.bookingImg,
            title: "Best Price Guaranteed",
            text: (<>
                With our transparent pricing and zero hidden fees for the best cabs in Vijayawada, we prioritize affordability without compromising quality or service standards. Whether it's a brief commute or an extensive long-distance journey, our best price guarantee ensures that every business drive delivers maximum cost-effectiveness and satisfaction for our valued customers.
            </>),
        },
        {
            imgSrc: imagePaths.phoneImg,
            title: "24/7 Customer Care",
            text: (
                <span>
                    Our corporate car services are supported by 24/7 customer care, guaranteeing our esteemed clients receive timely assistance and personalized attention at all hours. Whether it's scheduling reservations, adjusting bookings, or addressing inquiries and concerns, our devoted customer care team is accessible around the clock to deliver efficient and effective support.
                </span>
            ),
        },
        {
            imgSrc: imagePaths.emailImg,
            title: "Easy Bookings",
            text: (
                <span>
                    <a href="https://srcartravels.in/hyderabad-to-vijayawada-cab-booking" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>Booking a cab</a> with SR Car Travels is designed to be effortless, ensuring a stress-free experience for all our clients. Our intuitive online platform and committed customer service team simplify reservations with just a few clicks or a quick phone call. Whether it's an immediate ride or planning for upcoming business trips, our efficient booking process allows you to choose your preferred vehicle, specify pickup and drop-off locations, and effortlessly add any extra services or preferences.
                </span>
            )
        }
    ];

    return (
        <div className="container py-4">
            <h2 className="mb-4 text-center fs-4">What Makes Us The Preferred Choice for Business Executives?</h2>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {bookingOptions.map((option, index) => (
                    <div key={index} className="col">
                        <div className="card h-100">
                            <LazyLoadImage
                                src={option.imgSrc} // Now using the correct image for each option
                                alt={option.title}
                                effect="blur"
                                className="card-img-top"
                            />
                            <div className="card-body text-center">
                                <h3 className="card-title fs-5">{option.title}</h3>
                                <p className="card-text fs-6">{option.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BookingOptionsForCIV;
