import React from 'react';
import './SRAddress.css';

const SRAddress = () => {
  return (
    <div className="sr-address-container">
      <h2 className="sr-address-title">Visit Us</h2>
      <p className="sr-address">
      LIG 34,Ground Floor, APIIC Colony, Zamac Apartment Road, APIIC Colony Road, Guru Nanak Colony, Vijayawada, Andhra Pradesh 520007.
      </p>
    </div>
  );
};

export default SRAddress;
