import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ThankYouPage = () => {
    return (
        <>
            <Helmet>
                <title>Thank You for Your Submission - SR Car Travels</title>
                <meta name="description" content="We have received your submission and will get back to you soon." />
                <link rel="canonical" href="https://srcartravels.in/thank-you" />
                <meta name="robots" content="index, follow" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Thank You for Your Submission - SR Car Travels" />
                <meta property="og:description" content="We have received your submission and will get back to you soon." />

            </Helmet>
            <div className="container text-center">
                <h2>Thank You!</h2>
                <p>Your submission has been received. We will get back to you soon.</p>
                <Link to="/" className="btn btn-primary">
                    <i className="bi bi-house-door-fill"></i> Go Back Home
                </Link> {/* Use Bootstrap Icons here */}
            </div>
        </>
    );
};

export default ThankYouPage;
