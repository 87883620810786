import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponent = () => {
    const faqs = [
        {
            question: "How much will it cost to hire luxury cars from SR Travels?",
            answer: (
                <>
                    You can explore our various car rentals on our website. For detailed information on our Guntur car rental tariffs and to book your dream ride, contact our customer support team at Call Now: <a variant="outline-primary" href="tel:+918886556632">
                        <i className="bi bi-telephone-fill"></i> +91-8886556632
                    </a>.
                </>
            )
        },
        {
            question: "What are the various payment options available for booking?",
            answer: "Experience seamless transactions by choosing from our different range of payment options, including all major debit and credit cards, UPIs, and other convenient transaction methods. Simplify your travel experience with our diverse and hassle-free payment methods tailored to meet your needs."
        },
        {
            question: "Can I get SR cars/cabs at my disposal?",
            answer: "With SR Travels, you'll have access to cabs at your disposal for as long as you need, allowing you to travel to different destinations with just one booking within any city limits."
        },
        {
            question: "Do you sanitize your cars before lending them for rentals or journeys?",
            answer: "We ensure thorough cleaning and sanitization of our rental cars before and after every outstation and day-based journey to prioritize your health and safety."
        },
        {
            question: "Do you also provide round-trip services to your customers in Guntur?",
            answer: "We offer both one-way and round-trip car rental services in Guntur to cater to our customers' diverse travel needs."
        }
    ];

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "How much will it cost to hire luxury cars from SR Travels?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can explore our various car rentals on our website. For detailed information on our Guntur car rental tariffs and to book your dream ride, contact our customer support team at +91-8886556632"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What are the various payment options available for booking?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Experience seamless transactions by choosing from our different range of payment options, including all major debit and credit cards, UPIs, and other convenient transaction methods. Simplify your travel experience with our diverse and hassle-free payment methods tailored to meet your needs."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I get SR cars/cabs at my disposal?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "With SR Travels, you'll have access to cabs at your disposal for as long as you need, allowing you to travel to different destinations with just one booking within any city limits."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do you sanitize your cars before lending them for rentals or journeys?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We ensure thorough cleaning and sanitization of our rental cars before and after every outstation and day-based journey to prioritize your health and safety."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do you also provide round-trip services to your customers in Guntur?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We offer both one-way and round-trip car rental services in Guntur to cater to our customers' diverse travel needs."
                  }
                }
              ]
            }
          `}
                </script>
            </Helmet>
            <div className="container py-5">
                <h2 className="mb-4 text-center">FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body className="fw-bold">{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponent;
