import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SRTravelOptionsForLdc = () => {
    const imagePaths = {
        pdc_2: require("../assets/img/ldc-2.png"),
        pdc_3: require("../assets/img/ldc-3.png"),
        pdc_4: require("../assets/img/ldc-4.png"),
        pdc_5: require("../assets/img/ldc-5.png"),
    };

    // Custom style for the thin line
    const thinLineStyle = {
        width: '75%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
            <h2 className="text-center mb-4 fw-bold fs-4">Perks of Choosing SR Car Travels For Your Next Travel Excursion</h2>
            <div className="container">
                
                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.pdc_2}
                            alt="long drive cars in vijayawada: pdc_2"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Quick and Easy Car Rental Booking</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Our platform provides a seamless and efficient process for securing the ideal vehicle to suit your requirements, whether for a simple escapade, business endeavor, or daily travel. With an extensive array of vehicles at your disposal, you can swiftly locate and reserve your rental car with minimal effort. </p>
                        <p className='fs-6'>Bid farewell to lengthy queues and <a href="https://srcartravels.in/login" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>intricate booking formalities</a> - with us, renting a car has never been simpler & easier. </p>
                    </div>
                </div>
                <hr style={thinLineStyle} /> {/* Thin line after Outstation Services Section */}

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.pdc_3}
                            alt="long drive cars in vijayawada: pdc_3"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Top Car Brands & Models</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>Discover our handpicked assortment of premier automobile brands and models, celebrated for their outstanding performance, dependability, and sophistication. Embrace an array of options, spanning from prestigious luxury cars to practical and efficient choices tailored to accommodate diverse tastes and financial considerations.</p>
                        
                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.pdc_4}
                            alt="long drive cars in vijayawada: pdc_4"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>Reasonable Pricing</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>At SR Car Travels, we place a strong emphasis on delivering affordable solutions without sacrificing quality or comfort. Our transparent pricing models and absence of hidden fees provide you with the assurance that you're receiving exceptional value for your investment. Whether it's a brief excursion or an extended voyage, our competitive rates make <a href="https://srcartravels.in/car-travelers-near-me" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>car travel accessible to everyone</a>, ensuring a convenient and economical experience.</p>
                        
                    </div>
                </div>
                <hr style={thinLineStyle} />

                <div className="row justify-content-center align-items-center g-1 my-4">
                    <div className="col-lg-2 text-center mb-2">
                        <LazyLoadImage
                            src={imagePaths.pdc_5}
                            alt="long drive cars in vijayawada: pdc_5"
                            effect="blur"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-lg-4 text-left mb-2">
                        <h3 className='fs-3 fw-bold'>24X7 Service Available</h3>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <p className='fs-6'>At SR Car Travels, we are dedicated to ensuring customer satisfaction at all times, even beyond regular business hours. Our round-the-clock assistance services are designed to cater to your needs, whether you encounter an unexpected issue during your journey or require assistance with booking inquiries. Our committed team is available 24/7 to provide swift support and ensure a smooth travel experience. </p>
                        <p className='fs-6'>With our dependable customer service, you can enjoy your travels with confidence, knowing that help is readily available with just a phone call, no matter when or where.  </p>
                        <p className='fs-6'><a href="https://srcartravels.in/sp-offers" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>Book your preferred long drive car in Vijayawada here.</a></p>
                        
                    </div>
                </div>
                <hr style={thinLineStyle} />
                
            </div>
         
        </>
    );
};

export default SRTravelOptionsForLdc;
