import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FAQsComponentForVijTaxiService = () => {
    const faqs = [
        {
            question: "How can I contact SR Car Travels to book a taxi?",
            answer: "You can simply contact us at: +91-8886556632, or send your relevant inquiries to: contact@srcartravels.in"
        },
        {
            question: "What if I need to cancel my booking?",
            answer: "You are eligible for a full refund if you cancel at least 24 hours before your scheduled pickup time."
        },
        {
            question: "Can I hire a taxi from Vijayawada Airport to Hyderabad?",
            answer: "If you're considering traveling directly from Vijayawada airport to Hyderabad by road, SR Car Travels offers taxi services at every terminal of Vijayawada airport. This option allows you to take the stress out of transportation and enjoy a comfortable journey to your destination in Hyderabad."
        },
        {
            question: "For how many hours is a driver on duty?",
            answer: "When driving locally, the rental period extends for up to eight hours, and when traveling outstation, it extends for up to 12 hours. Additional hours beyond these limits typically result in extra fees."
        },
        {
            question: "Can I make the payment using my UPI ID?",
            answer: "Yes, we gladly accept various payment methods, including Debit & Credit Cards, as well as UPIs. We strive to offer convenient and easy options for our valued customers."
        }
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
            <div className="container py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <h2 className="mb-4 text-center">Contact FAQs</h2>
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body style={{ fontWeight: 'bold' }}>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default FAQsComponentForVijTaxiService;
